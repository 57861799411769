import styles from './InfoModal.module.css';
import UsageModal from './Usage/UsageModal';
import NoticeModal from './Notice/NoticeModal';
import UserMonitorModal from './UserMonitor/UserMonitorModal';
import EditNoticeModal from './EditNotice/EditNoticeModal';
import EditPeriodCategoryModal from './EditPeriodCategory/EditPeriodCategoryModal';
import ImageViewModal from './ImageView/ImageViewModal';

const InfoModal = ({infoModalPage, setInfoModalPage, modalProps, setNotices}) => {

  	return (
        <div className={styles.modalbackground}>
            <div className={styles.modalcontainer}>
                {infoModalPage==1 && <UsageModal setInfoModalPage={setInfoModalPage}/>}
                {infoModalPage==2 && <NoticeModal setInfoModalPage={setInfoModalPage} popupNoticeList={modalProps.popupNoticeList}/>}

                {infoModalPage==11 && <EditNoticeModal setInfoModalPage={setInfoModalPage} setNotices={setNotices}/>}
                {infoModalPage==12 && <UserMonitorModal setInfoModalPage={setInfoModalPage}/>}

                {infoModalPage==21 && <EditPeriodCategoryModal setInfoModalPage={setInfoModalPage}/>}

                {infoModalPage==31 && <ImageViewModal setInfoModalPage={setInfoModalPage} images={modalProps.images} />}

                {infoModalPage!=12 &&
                    <div className={styles.closebutton} onClick={()=>setInfoModalPage(0)}>
                        <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" />
                    </div>
                }
            </div>
        </div>
    );
};
    
export default InfoModal;