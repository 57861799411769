import styles from './BrowserSideTab.module.css';
import HotpleContents from '../Common/Contents/Hotple/HotpleContents';
import Menu from './Menu/Menu';
import HomeContents from '../Common/Contents/Home/HomeContents';

const BrowserSideTab = ({ setAuthModalPage, setInfoModalPage, activeMenu, setActiveMenu, notices, account, myHotples, mapHotples, adminHotples, updateHotpleInfo, mapLocation, setIsSearchMode, selectedHotple, setSelectedHotple, selectedOption, setSelectedOption, isAdmin }) => {
	return (
		<div className={styles.sideTab}>
			  	<Menu activeMenu={activeMenu} setActiveMenu={setActiveMenu} isAdmin={isAdmin}/>
			  	{activeMenu=='home' &&
					<HomeContents setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} activeMenu={activeMenu} setActiveMenu={setActiveMenu} setSelectedOption={setSelectedOption} notices={notices} account={account} myHotples={myHotples} mapHotples={mapHotples} updateHotpleInfo={updateHotpleInfo} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} />
				}
			  	{(activeMenu=='search' || activeMenu=='my' || activeMenu=='admin') &&
					<HotpleContents activeMenu={activeMenu} setActiveMenu={setActiveMenu} setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} notices={notices} account={account} myHotples={myHotples} mapHotples={mapHotples} adminHotples={adminHotples} updateHotpleInfo={updateHotpleInfo} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
				}
		</div>);
};

export default BrowserSideTab;