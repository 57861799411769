import styles from './ImageEditHotpleModal.module.css';
import api from '../../../../api'
import { useEffect, useState } from 'react';
import DetailHeader from '../DetailHeader/DetailHeader';
import { copyToClipboard } from '../../../../util';



const ImageEditHotpleModal = ({setHotpleModalPage, editedHotple, updateHotpleField, originalHotple, updateHotpleInfo}) => {
      const [imageErrors, setImageErrors] = useState([]);
      const [isPatching, setIsPatching] = useState(false);

      const handleNextButtonClick = async () => {
            setHotpleModalPage(0);
      };

      const fetchData = async (media_id) => {
            try {
                  const response = await api.get(`/admin/media/${media_id}/image_error`);
                  setImageErrors(response.data)
            } catch (error) {
                  console.error('Error fetching image error:', error);
            }
      };

      const tryPatchImage = async () => {
            if(isPatching)
                  return;
            
            setIsPatching(true);
            try {
                  const response = await api.patch(`/admin/media/${originalHotple.media_id}/image_patch`);

                  setHotpleModalPage(0);
                  updateHotpleInfo('updateHotpleInfoFromList', response.data.hotples[0]);
            } catch (error) {
                  console.error('Error fetching image error:', error);
            }
            finally{
                  setIsPatching(false);
            }
      };

      const parsePossibleUrls = (possibleUrls) => {
            try {
              return possibleUrls.map((url) => JSON.parse(url)); // 배열 요소를 JSON으로 파싱
            } catch (error) {
              console.error('Error parsing possible URL:', error);
              return [];
            }
      };

      const handleResponseClicked = (response) => {
            copyToClipboard(response);
            window.open(`https://jsonprettier.com/`, '_blank');
      }

      const handlePossibleUrlClicked = (key, url) => {
            copyToClipboard(key);
            window.open(url, '_blank');
      }

      useEffect(() => {
            if (originalHotple.media_id)
                  fetchData(originalHotple.media_id);
      }, [originalHotple]);

      return (
            <div className={styles.reviewmodal4}>
                  <div className={styles.mainframe}>
                        <div className={styles.headerframe}>
                              <b className={styles.b}>이미지 정보</b>
                              <a className={styles.instagrambutton} href={editedHotple.media_url} target="blank" rel="noopener noreferrer">
                                    게시글 확인하고 오기 →
                              </a>

                              {originalHotple.media_caption &&
                                    <DetailHeader hotple={editedHotple} updateHotpleField={updateHotpleField} updateHotpleInfo={updateHotpleInfo}/>
                              }
                        </div>
                        <div className={styles.mainframe1}>
                              <div className={styles.container}>
                                    {imageErrors.map((imageError, errorIndex) => (
                                          <div key={imageError.id} className={styles.errorBlock}>
                                                <div className={styles.infoSection}>
                                                      <div
                                                            className={styles.infoItem}
                                                            onClick={() =>
                                                                  copyToClipboard(imageError.media_id)
                                                            }
                                                      >
                                                            <strong>Media ID:</strong> {imageError.media_id}
                                                      </div>
                                                      <div
                                                            className={styles.infoItem}
                                                            onClick={() =>
                                                                  copyToClipboard(imageError.media_type)
                                                            }
                                                      >
                                                            <strong>Media Type:</strong>{" "}
                                                            {imageError.media_type}
                                                      </div>
                                                </div>
                                                <div className={styles.responsesSection}>
                                                      <h3>Responses</h3>
                                                      {imageError.responses.map((response, index) => (
                                                            <div key={index} className={styles.responseItem}>
                                                                  <div
                                                                        className={styles.responseBody}
                                                                        onClick={() =>
                                                                              handleResponseClicked(response)
                                                                        }
                                                                  >
                                                                        <pre>{response}</pre>
                                                                  </div>
                                                            </div>
                                                      ))}
                                                </div>
                                                <div className={styles.possibleUrlsSection}>
                                                      <h3>Possible URLs</h3>
                                                      <table className={styles.urlTable}>
                                                            <thead>
                                                                  <tr>
                                                                        <th>Key</th>
                                                                  </tr>
                                                            </thead>
                                                            <tbody>
                                                            {parsePossibleUrls(imageError.possible_urls).map((url, index) => (
                                                                  <tr key={index}>
                                                                  <td
                                                                  className={styles.clickable}
                                                                  onClick={() => handlePossibleUrlClicked(url.key, url.value)}
                                                                  >
                                                                  {url.key}
                                                                  </td>
                                                                  </tr>
                                                            ))}
                                                            </tbody>
                                                      </table>
                                                </div>
                                                <hr className={styles.divider} />
                                          </div>
                                    ))}
                              </div>
                        </div>
                  </div>
                  
                  <div className={styles.buttonFrame}>
                        <div className={styles.backButton} onClick={handleNextButtonClick}>
                              <div className={styles.nametext}>돌아가기</div>
                        </div>
                        <div className={isPatching?styles.addButtonDisabled : styles.addButton} onClick={tryPatchImage}>
                              <div className={styles.nametext}>이미지 갱신 시도</div>
                        </div>
                  </div>
            </div>
      );
};

export default ImageEditHotpleModal;