import React, { useState, useEffect } from 'react';
import api from './api.js';
import AuthModal from './Compoenent/Modal/Auth/AuthModal.js';
import styles from './MainFrame.module.css';
import NaverMap from './Compoenent/Map/NaverMap';
import BrowserSideTab from './Compoenent/SideTab/BrowserSideTab/BrowserSideTab.js';
import BottomSheet from './Compoenent/SideTab/MobileBottomSheet/MobileBottomSheet.tsx';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import NavigationBar from './Compoenent/SideTab/MobileBottomSheet/NavigationBar.js';
import UserModal from './Compoenent/Modal/User/UserModal.js';
import HotpleModal from './Compoenent/Modal/Hotple/HotpleModal.js';
import InfoModal from './Compoenent/Modal/Info/InfoModal.js';

const MainFrame = () => {
  const [authModalPage, setAuthModalPage] = useState(0);
  const [userModalPage, setUserModalPage] = useState(0);
  const [infoModalPage, setInfoModalPage] = useState(0);
  const [hotpleModalPage, setHotpleModalPage] = useState(0);
  const [mapLocation, setMapLocation] = useState({name: null, address: '', latitude: null, longitude: null});
  const [modalProps, setModalProps] = useState({});
  const [selectedOption, setSelectedOption] = useState({region:'전국', category: 'recommend', filter: 'all'})
  const [activeMenu, setActiveMenu] = useState('search');
  const [account, setAccount] = useState({hotpleId:null, instagramUsername:null});
  const [myHotples, setMyHotples] = useState({hotples:[]});
  const [rangeHotples, setRangeHotples] = useState({hotples:[]});
  const [recommendHotples, setRecommendHotples] = useState({hotples:[]});
  const [adminHotples, setAdminHotples] = useState({hotples:[]});
  const [mapHotples, setMapHotples] = useState({hotples:[]});
  const [notices, setNotices] = useState([]);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [selectedHotple, setSelectedHotple] = useState(null);
  const [periodHotpleGroup, setPeriodHotpleGroup] = useState([]);

  const handleAutoLogin = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const params = new URLSearchParams(window.location.search);
    const hotpleId = params.get('hotple_id');
    const menu = params.get('menu');

    if (refreshToken) {
      try {
        const response = await api.post('/auth/refresh_token', {
          refreshToken,
        });

        const { accessToken, refreshToken: newRefreshToken } = response.data;

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', newRefreshToken);

        getMyInfo();
      } catch (error) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        
        if((menu=='my' || menu=='admin') && hotpleId)
          setAuthModalPage(1);
      }
    }
    else{
      if((menu=='my' || menu=='admin') && hotpleId)
        setAuthModalPage(1);
    }
  };

  const cleanExpiredPopupNotices = () => {
    const now = new Date();

    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (key.startsWith('popup_')) {
            const storedExpiry = localStorage.getItem(key);

            if (storedExpiry) {
                const expiryDate = new Date(storedExpiry);
                if (expiryDate <= now) {
                    localStorage.removeItem(key);
                }
            }
        }
    }
  };

  const getNotices = async () => {
    cleanExpiredPopupNotices();

    const params = new URLSearchParams(window.location.search);
    const hotpleId = params.get('hotple_id');
    const menu = params.get('menu');

    if (!(menu || hotpleId)) {
        const response = await api.get(`/notice`);
        const noticeList = response.data;

        const popupNoticeList = noticeList.filter(notice => {
            if (notice.popup_image_url) {
                const storedExpiry = localStorage.getItem(`popup_${notice.id}`);
                if (storedExpiry) {
                    const expiryDate = new Date(storedExpiry);
                    if (expiryDate > new Date()) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        });

        setNotices(noticeList);

        if (popupNoticeList.length > 0) {
            setModalProps({ popupNoticeList: popupNoticeList });
            setInfoModalPage(2);
        }
    }
  };

  const getMyInfo = async () => {
    try {
      const response = await api.get(`/user/my/`);

      let accountInfo = {hotpleId:response.data.user_id, instagramUsername:response.data.instagram_username};
      if(response.data.is_admin)
        accountInfo.isAdmin = true;

      setAccount(accountInfo);
      if(response?.data?.new_hotples?.length >= 1){
        setModalProps({new_hotples: response.data.new_hotples});
        setUserModalPage(2);
      }

      getMyHotple(accountInfo.isAdmin);

      if(response.data.instagram_username==null)
        setAuthModalPage(3);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getMyHotple = async (is_admin=false) => {
    try {
      const response = await api.get(`/hotple/my/`);

      setMyHotples(response.data);

      const params = new URLSearchParams(window.location.search);
      const hotpleId = params.get('hotple_id');
      const menu = params.get('menu');

      if(menu == 'my' && hotpleId){
        setActiveMenu(menu);
        setTimeout(() => {
          const foundHotple = response.data.hotples.find(hotple => hotple.hotple_id == hotpleId);
          if(foundHotple)
            setSelectedHotple(foundHotple);
          
          const newUrl = window.location.pathname;
          window.history.replaceState({}, '', newUrl);
        }, 200);
      }
      else if(menu == 'admin' && hotpleId){
        if(is_admin)
          setActiveMenu(menu);
        else{
          const newUrl = window.location.pathname;
          window.history.replaceState({}, '', newUrl);
        }
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAdminHotples = async () => {
    try {
      const response = await api.get(`/admin/hotple/`);

      setAdminHotples(response.data);
      setFilteredMapHotples(response.data);

      const params = new URLSearchParams(window.location.search);
      const hotpleId = params.get('hotple_id');
      const menu = params.get('menu');

      if(menu == 'admin' && hotpleId){
        setTimeout(() => {
          const foundHotple = response.data.hotples.find(hotple => hotple.hotple_id == hotpleId);
          if(foundHotple)
            setSelectedHotple(foundHotple);
          
          const newUrl = window.location.pathname;
          window.history.replaceState({}, '', newUrl);
        }, 200);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateHotpleInfo = (action, hotple=null) => {
    switch(action){
      case 'updateIsPick':
        if(account.hotpleId!=null){
          updateHotpleInfoFromListExceptMyHotples(hotple);

          if(hotple.is_pick)
            addMyHotpleIfNotExist(hotple);
          else
            updateHotpleInfo('deleteMyHotple',hotple);
        }
        else
          setAuthModalPage(1);
        break;
      case 'showDeleteMyHotpleModal':
        setModalProps({hotple:hotple});
        setUserModalPage(1);
        break;
      case 'showDeleteHotpleModal':
        setModalProps({hotple:hotple, is_remove:true});
        setUserModalPage(1);
        break;
      case 'showVerifyHotpleModal':
        setModalProps({hotple:hotple, is_verify:true});
        setHotpleModalPage(1);
        break;
      case 'showEditHotpleModal':
        setModalProps({hotple:hotple});
        setHotpleModalPage(11);
        break;
      case 'showEditRequestModal':
        setModalProps({hotple:hotple});
        setHotpleModalPage(21);
        break;
      case 'showImageEditHotpleModal':
        setModalProps({hotple:hotple});
        setHotpleModalPage(31);
        break;
      case 'AddNewHotpleAndEdit':
        addAdminHotpleIfNotExist(hotple);
        setModalProps({hotple:hotple, is_verify:true});
        setHotpleModalPage(1);
        break;
      case 'deleteMyHotple':
        deleteMyHotple(hotple);
        updateHotpleInfoFromListExceptMyHotples(hotple);
        break;
      case 'deleteHotple':
        deleteHotple(hotple);
        break;
      case 'refreshAdminHotples':
        getAdminHotples();
        break;
      case 'updateHotpleImagesFromList':
        updateHotpleInfoFromListExceptMyHotples(hotple);
        updateMyHotple(hotple);
        break;
      case 'changeHotpleAfterInference':
        addAdminHotpleIfNotExist(hotple);
        break;
      case 'updateHotpleInfoFromList':
        updateHotpleInfoFromListExceptMyHotples(hotple);
        updateMyHotple(hotple);
    }
  };

  const updateHotpleInfoFromListExceptMyHotples = (hotple) =>{
    updateRangeHotple(hotple);
    updateRecommendHotple(hotple);
    updateAdminHotple(hotple);
    updateMapHotple(hotple);
  };

  const deleteMyHotple = async (deleteHotple) => {
    if(myHotples.hotples.some(hotple => hotple.hotple_id == deleteHotple.hotple_id)){
      setMyHotples({hotples: myHotples.hotples.filter(hotple => hotple.hotple_id !== deleteHotple.hotple_id)});
      setUserModalPage(0);
      if(activeMenu=='my')  //my탭에서 hotple delete 시, info창 없애기 위해
        setSelectedHotple(null);

      try {
        const response = await api.patch(`/user/pick/`,{
          is_pick: false,
          hotple_id: deleteHotple.hotple_id
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  const deleteHotple = async (deleteHotple) => {
    setSelectedHotple(null);

    setMyHotples({hotples: myHotples.hotples.filter(hotple => hotple.hotple_id !== deleteHotple.hotple_id)});
    setAdminHotples({hotples: adminHotples.hotples.filter(hotple => hotple.hotple_id !== deleteHotple.hotple_id)});
    setRangeHotples({hotples: rangeHotples.hotples.filter(hotple => hotple.hotple_id !== deleteHotple.hotple_id)});
    setRecommendHotples({hotples: recommendHotples.hotples.filter(hotple => hotple.hotple_id !== deleteHotple.hotple_id)});
    
    setUserModalPage(0);

    try {
      await api.delete(`/admin/hotple/${deleteHotple.hotple_id}`);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const updateMyHotple = (newHotple) => {
    setMyHotples({hotples: myHotples.hotples.map(hotple => hotple.hotple_id === newHotple.hotple_id ? newHotple : hotple)});
  }

  const updateRangeHotple = async (newHotple) => {
    setRangeHotples({hotples: rangeHotples.hotples.map(hotple => hotple.hotple_id === newHotple.hotple_id ? newHotple : hotple)});
  }

  const updateRecommendHotple = async (newHotple) => {
    setRecommendHotples({hotples: recommendHotples.hotples.map(hotple => hotple.hotple_id === newHotple.hotple_id ? newHotple : hotple)});
  }

  const updateAdminHotple = async (newHotple) => {
    setAdminHotples({hotples: adminHotples.hotples.map(hotple => hotple.hotple_id === newHotple.hotple_id ? newHotple : hotple)});
  }

  const updateMapHotple = async (newHotple) => {
    setMapHotples({hotples: mapHotples.hotples.map(hotple => hotple.hotple_id === newHotple.hotple_id ? newHotple : hotple)});
  }

  const addMyHotpleIfNotExist = async (newHotple) => {
    if(!myHotples.hotples.some(hotple => hotple.hotple_id == newHotple.hotple_id)){
      setMyHotples({hotples: [...myHotples.hotples, newHotple]});
    }

    try {
			const response = await api.patch(`/user/pick/`,{
				is_pick: true,
				hotple_id: newHotple.hotple_id
			});
		} catch (error) {
			console.error('Error fetching data:', error);
		}
  }

  const addAdminHotpleIfNotExist = async (newHotple) => {
    if(!adminHotples.hotples.some(hotple => hotple.hotple_id == newHotple.hotple_id)){
      setAdminHotples({hotples: [newHotple, ...adminHotples.hotples.filter(hotple => hotple.hotple_id !== newHotple.old_hotple_id) ]});
    }
  }

  const setFilteredMapHotples = (hotples) => {
    if(activeMenu=='home'){
      setMapHotples(hotples);
    }
    else{
      let category_kor;
      switch(selectedOption.category) {
        case "cafe":
            category_kor='카페디저트'
            break;
        case "alcohol":
            category_kor='주점'
            break;
        case "restaurant":
            category_kor='음식점'
            break;
        case "popupStore":
            category_kor='팝업스토어'
            break;
        case "cultural":
            category_kor='문화예술'
            break;
        case "activity":
            category_kor='오락액티비티'
            break;
        case "nature":
            category_kor='자연힐링'
            break;
        default:
            category_kor='추천'
      }

      let filteredHotples = hotples;

      if(selectedOption.region!='전국' && selectedOption.region!='위치'){
        filteredHotples = {hotples: filteredHotples.hotples.filter(hotple => hotple.region == selectedOption.region)}
      }

      if(selectedOption.category!='recommend' && !selectedOption.category.startsWith('@'))
        filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => hotple.main_category == category_kor)}

      if(activeMenu=='my' || activeMenu=='admin'){
        switch(selectedOption.filter){
          case null:
          case 'all':
            break;
          case 'need_verify':
            filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => hotple.need_verify == true)}
            break;
          case 'my_pick':
            if(activeMenu=='admin')
              setSelectedOption((prev)=>({...prev, filter:'all'}))
            else
              filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => hotple.taggedBy == null)}
            break;
          case 'has_period':
            filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => hotple.has_period == true)}
            break;
          case 'edit_request':
            if(activeMenu=='my')
              setSelectedOption((prev)=>({...prev, filter:'all'}))
            else
              filteredHotples = {hotples: filteredHotples.hotples.filter(hotple => hotple.verify_results && hotple.verify_results.length>0)};
            break;
          case 'err_image':
            if(activeMenu=='my')
              setSelectedOption((prev)=>({...prev, filter:'all'}))
            else
              filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => !hotple.preview_url || !hotple.contents_url || hotple.contents_url.length==0)}
            break;
          case 'err_info':
            if(activeMenu=='my')
              setSelectedOption((prev)=>({...prev, filter:'all'}))
            else
              filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => !hotple.name || !hotple.caption || !hotple.main_category || !hotple.sub_category1 || !hotple.sub_category2 || !hotple.tags || !hotple.place_name || !hotple.address || !hotple.region || !hotple.latitude || !hotple.longitude)}
            break;
          case 'err_place':
            if(activeMenu=='my')
              setSelectedOption((prev)=>({...prev, filter:'all'}))
            else
              filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => hotple.address && !((hotple.region == hotple.address.substring(0,2)) || hotple.region == hotple.address.charAt(0) + hotple.address.charAt(2)))}
            break;
          case 'err_kakao':
            if(activeMenu=='my')
              setSelectedOption((prev)=>({...prev, filter:'all'}))
            else
              filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => !hotple.kakao_map_place_id)}
            break;
          default:  //username인 경우
            filteredHotples = {hotples:filteredHotples.hotples.filter(hotple => (hotple.taggedBy == selectedOption.filter || (hotple.comment && hotple.comment.includes(selectedOption.filter))))}
        }
      }

      setMapHotples(filteredHotples);
    }
  };

  async function getHotpleGroup(){
    const response = await api.get(`/hotple_groups`);

    if(response)
      setPeriodHotpleGroup(response.data);
  }

  const showTermsAndConditions = (idx) => {
		switch(idx){
			case 1:
				setModalProps({images:['images/terms/a.jpg']});
				break;
			case 2:
				setModalProps({images:['images/terms/b.jpg']});
				break;
			case 3:
				setModalProps({images:['images/terms/c.jpg']});
				break;	
		}

		setInfoModalPage(31);
	};

  useEffect(() => {
    handleAutoLogin();
    getNotices();
    getHotpleGroup();

    const params = new URLSearchParams(window.location.search);
    const idx = params.get('idx');
    const menu = params.get('menu');
    const path = window.location.pathname;

    if(menu == 'terms' && idx){
      showTermsAndConditions(Number(idx));

      const newUrl = path;
      window.history.replaceState({}, '', newUrl);
    }
    else if (path.endsWith('/usage')) {
      setInfoModalPage(1);

      const newUrl = window.location.origin;
      window.history.replaceState({}, '', newUrl);
    }
  }, []);

  useEffect(() => {
    setSelectedHotple(null);
    if(activeMenu=='my'){
      if(account.hotpleId!=null)
        setFilteredMapHotples(myHotples);
      else{
        setAuthModalPage(1);
        setMapHotples({hotples: []})
      }
    }
    else if(activeMenu=='home')
      setFilteredMapHotples(recommendHotples);
    else if(activeMenu=='admin'){
      if(adminHotples.hotples.length>0)
        setFilteredMapHotples(adminHotples);
      else{
        getAdminHotples();
      }
    }

    if(activeMenu!='search'){
      if(selectedOption.category.startsWith('@')){
        setSelectedOption((prev)=>
          ({...prev,
            region: '전국',
            category:'recommend'
          }))
      }
      else{
        setSelectedOption((prev)=>
          ({...prev,
            region: '전국'
          }));
      }
    }
  }, [activeMenu]);

  useEffect(() => {
      if(activeMenu=='search')
        setFilteredMapHotples(rangeHotples);
  }, [rangeHotples]);

  useEffect(() => {
      if(activeMenu=='home')
        setFilteredMapHotples(recommendHotples);
  }, [recommendHotples]);

  useEffect(()=>{
    if(activeMenu=='my' && account.hotpleId!=null)
      setFilteredMapHotples(myHotples);
  }, [myHotples])

  useEffect(()=>{
    if(activeMenu=='admin' && account.hotpleId!=null)
      setFilteredMapHotples(adminHotples);
  }, [adminHotples])

  useEffect(() => {
    if(account.hotpleId==null){
      setMyHotples({hotples:[]});
      if(activeMenu=='my' || activeMenu=='admin')
        setActiveMenu('search');
    }
  },[account])

  useEffect(() => {
    if(activeMenu=='my' && account.hotpleId!=null)
      setFilteredMapHotples(myHotples);
    else if(activeMenu=='admin' && account.hotpleId!=null)
      setFilteredMapHotples(adminHotples);
  },[selectedOption])

  useEffect(() => {
		if(isSearchMode && activeMenu=='home')
      setActiveMenu('search');
	}, [isSearchMode])

  return (
    <div className={styles.mainFrameContainer}>
      <BrowserView className={styles.browserContainer}>
          <BrowserSideTab setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} activeMenu={activeMenu} setActiveMenu={setActiveMenu} notices={notices} account={account} myHotples={myHotples} mapHotples={mapHotples} adminHotples={adminHotples} updateHotpleInfo={updateHotpleInfo} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} selectedOption={selectedOption} setSelectedOption={setSelectedOption} isAdmin={account.isAdmin}/>
          <NaverMap activeMenu={activeMenu} setActiveMenu={setActiveMenu} selectedOption={selectedOption} setSelectedOption={setSelectedOption} myHotples={myHotples} mapHotples={mapHotples} setRangeHotples={setRangeHotples} setRecommendHotples={setRecommendHotples} mapLocation={mapLocation} setMapLocation={setMapLocation} isSearchMode={isSearchMode} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} setInfoModalPage={setInfoModalPage} periodHotpleGroup={periodHotpleGroup}/>
      </BrowserView>

      <MobileView className={styles.mobileContainer}>
          <NaverMap activeMenu={activeMenu} setActiveMenu={setActiveMenu} selectedOption={selectedOption} setSelectedOption={setSelectedOption} myHotples={myHotples} mapHotples={mapHotples} setRangeHotples={setRangeHotples} setRecommendHotples={setRecommendHotples} mapLocation={mapLocation} setMapLocation={setMapLocation} isSearchMode={isSearchMode} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} setInfoModalPage={setInfoModalPage} periodHotpleGroup={periodHotpleGroup}/>
          <BottomSheet activeMenu={activeMenu} setActiveMenu={setActiveMenu} setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} notices={notices} account={account} myHotples={myHotples} mapHotples={mapHotples} adminHotples={adminHotples} updateHotpleInfo={updateHotpleInfo} mapLocation={mapLocation} isSearchMode={isSearchMode} setIsSearchMode={setIsSearchMode} selectedHotple={selectedHotple} setSelectedHotple={setSelectedHotple} selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
          <NavigationBar activeMenu={activeMenu} setActiveMenu={setActiveMenu} isAdmin={account.isAdmin}/>
      </MobileView>
      {authModalPage!=0 && <AuthModal authModalPage={authModalPage} setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} setModalProps={setModalProps} getMyInfo={getMyInfo} setAccount={setAccount}/>}
      {userModalPage!=0 && <UserModal userModalPage={userModalPage} setUserModalPage={setUserModalPage} modalProps={modalProps} myHotples={myHotples} updateHotpleInfo={updateHotpleInfo}/>}
      {hotpleModalPage!=0 && <HotpleModal hotpleModalPage={hotpleModalPage} setHotpleModalPage={setHotpleModalPage} modalProps={modalProps} updateHotpleInfo={updateHotpleInfo}/>}
      {infoModalPage!=0 && <InfoModal infoModalPage={infoModalPage} setInfoModalPage={setInfoModalPage} modalProps={modalProps} setNotices={setNotices}/>}
    </div>
  );
};


export default MainFrame;