import styles from './ReviewHotpleModal4.module.css';
import api from '../../../../api'
import { useEffect, useState } from 'react';
import DetailHeader from '../DetailHeader/DetailHeader';



const ReviewHotpleModal4 = ({setHotpleModalPage, editedHotple, updateHotpleField, originalHotple, updateHotpleInfo, needCheck}) => {
      const [isEdited, setIsEdited] = useState(false);
      const [isPatching, setIsPatching] = useState(false);

      const handleNextButtonClick = async () => {
            if(originalHotple.need_verify || isEdited){
                  try {
                        let hotple = {};

                        Object.keys(editedHotple).forEach((key) => {
                              if (editedHotple[key] != originalHotple[key])
                                    hotple[key] = editedHotple[key];
                        });

                        hotple['hotple_id']=originalHotple.hotple_id;
                        if('comment' in hotple)
                              hotple['comment_id']=originalHotple.comment_id;
                  
                        const response = await api.post('/hotple/verify', {
                              hotple,
                              needCheck
                        });

                        setHotpleModalPage(0);
                        updateHotpleInfo('updateHotpleInfoFromList', response.data.hotples[0]);
                  } catch (error) {
                        console.error("Error in handleNextButtonClick:", error);
                  }
            }
      };

      const handleReInferenceButtonClicked = async () => {
            if(isPatching)
                  return;

            const confirmDelete = window.confirm(`핫플 정보를 다시 AI로 분석하시겠습니까?`);
    
            if (confirmDelete) {
                  setIsPatching(true);
                  try {
                        const response = await api.patch(`/admin/hotple/${originalHotple.hotple_id}`);

                        const new_hotple = response.data.hotples[0];
                        new_hotple.old_hotple_id = originalHotple.hotple_id;

                        setHotpleModalPage(0);
                        updateHotpleInfo('changeHotpleAfterInference', new_hotple);
                  } catch (error) {
                        setIsPatching(false);
                  }
            }
      }

      const handleFrameClick = async (page) => {
            let targetPage = page;
            if(!originalHotple.need_verify)
                  targetPage += 11;

            setHotpleModalPage(targetPage);
      };

      useEffect(() => {
            let isEditFlag = false;
            Object.keys(editedHotple).forEach((key) => {      
                  if(key!='contents_url' && key!='preview_url'){
                        if (editedHotple[key] != originalHotple[key])
                              isEditFlag = true;
                  }
            });
            setIsEdited(isEditFlag);
      },[editedHotple]);

      return (
            <div className={styles.reviewmodal4}>
                  <div className={styles.mainframe}>
                        <div className={styles.headerframe}>
                              {originalHotple.need_verify ?
                                    <b className={styles.b}>이렇게 마무리할까요?</b>
                              :
                                    <b className={styles.b}>어떤 정보를 수정할까요?</b>
                              }
                              <a className={styles.instagrambutton} href={editedHotple.media_url} target="blank" rel="noopener noreferrer">
                                    게시글 확인하고 오기 →
                              </a>

                              {originalHotple.media_caption &&
                                    <>
                                          <DetailHeader hotple={editedHotple} updateHotpleField={updateHotpleField} updateHotpleInfo={updateHotpleInfo}/>
                                          <div className={isPatching ? styles.instagrambuttonDisabled : styles.instagrambutton} onClick={handleReInferenceButtonClicked}>
                                                핫플 AI 재분석
                                          </div>
                                    </>
                              }
                        </div>
                        <div className={styles.mainframe1}>
                              <div className={styles.nameframe} onClick={()=>handleFrameClick(1)}>
                                    <div className={styles.nametext}>{editedHotple.name}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              <div className={styles.categoryframe} onClick={()=>handleFrameClick(1)}>
                                    <div className={styles.tagplaceDetail}>
                                                <div className={styles.maincategorytext}>{editedHotple.main_category}</div>
                                    </div>
                                    <div className={styles.tagplaceDetail1} >
                                                <div className={styles.maincategorytext}>{`${editedHotple.sub_category1} > ${editedHotple.sub_category2}`}</div>
                                    </div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              <div className={styles.tagsframe} onClick={()=>handleFrameClick(2)}>
                                    <div className={styles.tags1}>
                                                <div className={styles.maincategorytext}>#{editedHotple.tags && editedHotple.tags[0]}</div>
                                    </div>
                                    <div className={styles.tags1}>
                                                <div className={styles.maincategorytext}>#{editedHotple.tags && editedHotple.tags[1]}</div>
                                    </div>
                                    <div className={styles.tags1}>
                                                <div className={styles.maincategorytext}>#{editedHotple.tags && editedHotple.tags[2]}</div>
                                    </div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              <div className={styles.captionframe} onClick={()=>handleFrameClick(2)}>
                                    <div className={styles.captiontext}>{editedHotple.caption}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              <div className={styles.periodframe} onClick={()=>handleFrameClick(2)}>
                                    <div className={styles.label1}>기간:</div>
                                    <div className={styles.periodtext}>{editedHotple.start_date?editedHotple.start_date:"시작일 없음"} ~ {editedHotple.end_date?editedHotple.end_date:"종료일 없음"}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              <div className={styles.placeframe} onClick={()=>handleFrameClick(3)}>
                                    <div className={styles.label1}>장소:</div>
                                    <div className={styles.periodtext}>{editedHotple.place_name}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              <div className={styles.placeframe} onClick={()=>handleFrameClick(3)}>
                                    <div className={styles.label1}>주소:</div>
                                    <div className={styles.periodtext}>[{editedHotple.region}] {editedHotple.address}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              {!originalHotple.need_verify && originalHotple.comment!=null &&
                              <div className={styles.commentframe}>
                                    {originalHotple.taggedBy==null ?
                                    <>
                                          <div className={styles.commentdiv}>내 댓글 :</div>
                                          <div className={styles.commentinput}>
                                                <input
                                                      type="text"
                                                      id="commentInput1"
                                                      className={styles.commentInput1}
                                                      value={editedHotple.comment}
                                                      onChange={(e) => updateHotpleField("comment",e.target.value)}
                                                      placeholder={originalHotple.comment}
                                                      autoComplete="off"
                                                />
                                          </div>
                                    </>
                                    :
                                    <>
                                          <div className={styles.commentdivTaggedBy}>댓글</div>
                                          <div className={styles.comment}>
                                                <a className={styles.label} href={`https://instagram.com/${originalHotple.taggedBy}`} target="_blank" rel="noopener noreferrer">@{originalHotple.taggedBy} :</a>
                                                {originalHotple.comment.split(/(@[\w\.]+)/g).map((part, index) => {
                                                      if (part.startsWith('@')) {
                                                      // 유저 태그 부분 처리
                                                      const username = part.slice(1); // '@' 제거한 유저 이름
                                                      return (
                                                            <a
                                                            key={index}
                                                            className={styles.commentUser}
                                                            href={`https://instagram.com/${username}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            >
                                                            {part}                       
                                                            </a>
                                                      );
                                                      } else {
                                                      // 일반 텍스트 처리
                                                      return (
                                                            <span key={index} className={styles.span}>
                                                            {part}
                                                            </span>
                                                      );
                                                      }
                                                })}
                                          </div>
                                    </>
                                    }
                              </div>
                              }
                        </div>
                  </div>
                  
                  {originalHotple.need_verify ?
                        <div className={styles.finishButton} onClick={handleNextButtonClick}>
                              <div className={styles.nametext}>검토 완료!</div>
                        </div>
                  :
                        <div className={isEdited ? styles.finishButton : styles.finishButtonDisabled} onClick={handleNextButtonClick}>
                              <div className={styles.nametext}>정보 수정 요청</div>
                        </div>
                  }
            </div>
      );
};

export default ReviewHotpleModal4;