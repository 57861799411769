import styles from './NavigationBar.module.css';


const NavigationBar = ({activeMenu, setActiveMenu, isAdmin}) => {
  	return (
		<div className={styles.tabBarglobal}>
			<div className={`${styles.div2} ${activeMenu === 'home' ? styles.active : ''}`}
				onClick={() => setActiveMenu('home')}
			>
				<div className={styles.content}>
					<div className={styles.frame}>
							<img className={styles.icon} alt="" src="images/home_outlined.svg" />
					</div>
					<div className={styles.div1}>홈</div>
				</div>
			</div>

			<div className={`${styles.div} ${activeMenu === 'search' ? styles.active : ''}`}
				onClick={() => setActiveMenu('search')}
			>
				<div className={styles.content}>
					<div className={styles.frame}>
							<img className={styles.icon} alt="" src="images/search.svg" />
					</div>
					<div className={styles.div1}>핫플찾기</div>
				</div>
			</div>

			<div className={`${styles.div2} ${activeMenu === 'my' ? styles.active : ''}`}
				onClick={() => setActiveMenu('my')}
			>
				<div className={styles.content}>
					<div className={styles.frame}>
							<img className={styles.icon} alt="" src="images/like_outlined.svg" />
					</div>
					<div className={styles.div1}>내 핫플</div>
				</div>
			</div>

			{isAdmin &&
				<div className={`${styles.div2} ${activeMenu === 'admin' ? styles.active : ''}`}
					onClick={() => setActiveMenu('admin')}
				>
					<div className={styles.content}>
						<div className={styles.frame}>
								<img className={styles.icon} alt="" src="images/setting.svg" />
						</div>
						<div className={styles.div1}>관리자</div>
					</div>
				</div>
			}
		</div>
	);
};

export default NavigationBar;
