import { useEffect, useRef } from 'react';
import LoginHeader from '../../Header/LoginHeader';
import UserHeader from '../../Header/UserHeader';
import styles from './HomeContents.module.css';
import HotpleItem from '../Hotple/HotpleItem/HotpleItem';
import { BrowserView, MobileView } from 'react-device-detect';
import { forceCheck } from 'react-lazyload';


const HomeContents = ({setAuthModalPage, setInfoModalPage, activeMenu, setActiveMenu, setSelectedOption, notices, account, myHotples, mapHotples, updateHotpleInfo, selectedHotple, setSelectedHotple}) => {
	const hotpleRefs = useRef({});

	useEffect(() => {
		if (selectedHotple && hotpleRefs.current[selectedHotple.hotple_id]) {
		hotpleRefs.current[selectedHotple.hotple_id].scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		}
	}, [selectedHotple]);

	useEffect(() => {
		forceCheck();
	}, [mapHotples]);

  	return (
		<div className={styles.frame}>
			<BrowserView className={styles.contents}>
				{account.hotpleId == null ? <LoginHeader setAuthModalPage={setAuthModalPage} /> : <UserHeader setAuthModalPage={setAuthModalPage} activeMenu={activeMenu} setActiveMenu={setActiveMenu} setSelectedOption={setSelectedOption} account={account} myHotples={myHotples}/>}
				<div className={styles.divider} />
				<div className={styles.maincontents} id='hotpleitems-container'>
					<div className={styles.innercontents}>
						<div className={styles.topcontents}>
							<div className={styles.title}>
								<div className={styles.div}>더 다양한 기능들이 추가될 예정이에요 :)</div>
								<b className={styles.hotple}>
									<span>{`인스타 핫플 찾을 땐, `}</span>
									<span className={styles.hotple1}>HOTPLE!</span>
								</b>
							</div>
							<div className={styles.funcButtons}>
								<div className={styles.recommendbutton}>
									<div className={styles.background} />
									<img className={styles.iconmyLocation} alt="" src="images/my_location.svg" />
									<div className={styles.text}>
										<b className={styles.ai}>AI 핫플 추천</b>
										<div className={styles.div1}>
											<p className={styles.p}>어디로 다녀오지?</p>
											<p className={styles.p}>고민중독인 당신을 위해!</p>
										</div>
									</div>
									<div className={styles.recommendbuttonChild} />
									<div className={styles.iconlockParent}>
										<img className={styles.iconlock} alt="" src="images/lock.svg" />
										<div className={styles.div2}>업데이트 예정!</div>
									</div>
								</div>
								<div className={styles.recommendbutton}>
									<div className={styles.background1} />
									<img className={styles.iconfire} alt="" src="images/fire.svg" />
									<div className={styles.text}>
										<b className={styles.ai}>실시간 핫플</b>
										<div className={styles.div1}>
											<p className={styles.p}>지금 내 주변</p>
											<p className={styles.p}>가장 핫한 곳은 어디?</p>
												</div>
											</div>
											<div className={styles.recommendbuttonChild} />
											<div className={styles.iconlockGroup}>
												<img className={styles.iconlock} alt="" src="images/lock.svg" />
												<div className={styles.div2}>업데이트 예정!</div>
											</div>
										</div>
									</div>
									<div className={styles.bannersFrame}>
										<img className={styles.bannerNotice} alt="" src="images/usage/banner.png" onClick={()=>setInfoModalPage(1)}/>
										{notices && notices.map(notice => (
											notice.banner_image_url && (
												<a href={notice.notice_url} target="_blank" rel="noopener noreferrer" key={notice.id}>
													<img className={styles.bannerNotice} alt="" src={notice.banner_image_url} />
												</a>
											)
										))}
									</div>
								</div>
								<div className={styles.bottomcontents}>
									<div className={styles.title1}>
											<b className={styles.ai}>내 주변 추천 HOTPLE ❤️</b>
									</div>
									<div className={styles.hotpleitems}>
										{mapHotples.hotples.map(hotple=>(
											<div
												key={hotple.hotple_id}
												ref={el => hotpleRefs.current[hotple.hotple_id] = el} // 각 HotpleItem에 대한 ref 할당
											>
												<HotpleItem key={hotple.hotple_id} hotple={hotple} activeMenu={activeMenu} updateHotpleInfo={updateHotpleInfo} setSelectedHotple={setSelectedHotple} isSelected={selectedHotple && hotple.hotple_id==selectedHotple.hotple_id}/>
											</div>
										))}
									</div>
									<div className={styles.contents1} />
								</div>
							</div>
						</div>
					</BrowserView>

			<MobileView className={styles.contents}>
				{selectedHotple==null && 
				<>
					{account.hotpleId == null ? <LoginHeader setAuthModalPage={setAuthModalPage} /> : <UserHeader setAuthModalPage={setAuthModalPage} activeMenu={activeMenu} setActiveMenu={setActiveMenu} setSelectedOption={setSelectedOption} account={account} myHotples={myHotples}/>}
				
				<div className={styles.divider} />
				</>
				}
				<div className={styles.maincontents}>
					<div className={styles.innercontents}>
					{selectedHotple==null && 
					<>
						<div className={styles.topcontents}>
							<div className={styles.title}>
								<div className={styles.div}>더 다양한 기능들이 추가될 예정이에요 :)</div>
								<b className={styles.hotple}>
									<span>{`인스타 핫플 찾을 땐, `}</span>
									<span className={styles.hotple1}>HOTPLE!</span>
								</b>
							</div>
							<div className={styles.funcButtons}>
								<div className={styles.recommendbutton}>
									<div className={styles.background} />
									<img className={styles.iconmyLocation} alt="" src="images/my_location.svg" />
									<div className={styles.text}>
										<b className={styles.ai}>AI 핫플 추천</b>
										<div className={styles.div1}>
											<p className={styles.p}>어디로 다녀오지?</p>
											<p className={styles.p}>고민중독인 당신을 위해!</p>
										</div>
									</div>
									<div className={styles.recommendbuttonChild} />
									<div className={styles.iconlockParent}>
										<img className={styles.iconlock} alt="" src="images/lock.svg" />
										<div className={styles.div2}>업데이트 예정!</div>
									</div>
								</div>
								<div className={styles.recommendbutton}>
									<div className={styles.background1} />
									<img className={styles.iconfire} alt="" src="images/fire.svg" />
									<div className={styles.text}>
										<b className={styles.ai}>실시간 핫플</b>
										<div className={styles.div1}>
											<p className={styles.p}>지금 내 주변</p>
											<p className={styles.p}>가장 핫한 곳은 어디?</p>
												</div>
											</div>
											<div className={styles.recommendbuttonChild} />
											<div className={styles.iconlockGroup}>
												<img className={styles.iconlock} alt="" src="images/lock.svg" />
												<div className={styles.div2}>업데이트 예정!</div>
											</div>
										</div>
									</div>
									<div className={styles.bannersFrame}>
										<img className={styles.bannerNotice} alt="" src="images/usage/banner.png" onClick={()=>setInfoModalPage(1)}/>
										{notices.map(notice => (
											notice.banner_image_url && (
												<a href={notice.notice_url} target="_blank" rel="noopener noreferrer" key={notice.id}>
													<img className={styles.bannerNotice} alt="" src={notice.banner_image_url} />
												</a>
											)
										))}
									</div>
								</div>
							</>
						}
								<div className={styles.bottomcontents}>
								{selectedHotple==null && 
								<>
									<div className={styles.title1}>
											<b className={styles.ai}>내 주변 추천 HOTPLE ❤️</b>
									</div>
								</>
								}
									<div className={styles.hotpleitems}>
										{selectedHotple==null ?
											mapHotples.hotples.map(hotple=>(
												<HotpleItem key={hotple.hotple_id} hotple={hotple} activeMenu={activeMenu} updateHotpleInfo={updateHotpleInfo} setSelectedHotple={setSelectedHotple}/>
											))
										:
											<HotpleItem key={selectedHotple.hotple_id} hotple={mapHotples?.hotples?.find(hotple => hotple.hotple_id == selectedHotple?.hotple_id)} activeMenu={activeMenu} updateHotpleInfo={updateHotpleInfo} setSelectedHotple={setSelectedHotple} isSelected={true}/>
										}
									</div>
									<div className={styles.contents1} />
								</div>
							</div>
						</div>
				</MobileView>
			</div>
	);
};

export default HomeContents;
