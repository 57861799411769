import { useEffect } from 'react';
import styles from './ImageViewModal.module.css';


const ImageViewModal = ({setInfoModalPage, images}) => {
    useEffect(()=>{
        console.log(images);
    },[]);
  	return (
        <div className={styles.editnoticemodal}>
        <div className={styles.contentframe}>
        {images &&
            images.map(image => {
                return (
                    <img 
                        className={styles.contentframeIcon} 
                        alt="" 
                        src={image}
                    />
                );
            })
        }
        </div>
        <div className={styles.buttonFrame}>
            <div className={styles.addButton} onClick={()=>setInfoModalPage(0)}>
                <div className={styles.nametext}>돌아가기</div>
            </div>
        </div>
    </div>
    );
};

export default ImageViewModal;
            