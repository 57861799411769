import styles from './UserHeader.module.css';


const UserHeader = ({setAuthModalPage, account, setActiveMenu, setSelectedOption, myHotples}) => {
    const handleLogout = async () => {
		setAuthModalPage(4);
    };

	const handleConnectInstagram = async () => {
		setAuthModalPage(3);
	}

	const handleHotpleInfoClicked = (filter) => {
		setActiveMenu('my');
		setSelectedOption(prev => ({ ...prev, filter: filter }));
	}

  	return (
    		<div className={styles.userheader}>
				<div className={styles.accountinfo}>
					<div className={styles.hotpleaccountinfo}>
						<div className={styles.div}>핫플 계정</div>
						<div className={styles.hotpleaccountid}>{account.hotpleId}</div>
						<div className={styles.logoutbutton} onClick={handleLogout}>로그아웃</div>
					</div>
					<div className={styles.hotpleaccountinfo}>
						<div className={styles.div}>인스타 계정</div>
						<a className={styles.instagramaccountid} href={`https://instagram.com/${account.instagramUsername}/`} target="_blank" rel="noopener noreferrer">{account.instagramUsername!=null?'@'+account.instagramUsername:'미연결'}</a>
						<div className={styles.connectinstagrambutton1} onClick={handleConnectInstagram}>{account.instagramUsername!=null?'연결 계정 변경':'계정 연결 하기'}</div>
					</div>
				</div>
				{account.instagramUsername==null &&
					<div className={styles.connectinstagramframe}>
							<div className={styles.pickContainer}>
								<p className={styles.pick}>인스타그램에서 PICK 한 장소를 보기 위해선</p>
								<p className={styles.pick}>인스타그램 계정 연결이 필요해요!</p>
							</div>
							<div className={styles.connectinstagrambutton2} onClick={handleConnectInstagram}>
								<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
								<div className={styles.text}>Instagram 계정 연결</div>
							</div>
					</div>
				}
      			<div className={styles.hotpleinfos}>
        				<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('all')}>
          					<div className={styles.pick1}>내 핫플</div>
          					<div className={styles.myhotplenum}>{myHotples.hotples ? myHotples.hotples.length : 0}개</div>
        				</div>
						<div className={styles.verticalDivider}/>
        				<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('my_pick')}>
          					<div className={styles.pick2}>내 PICK</div>
          					<div className={styles.myhotplenum2}>{myHotples.hotples ? myHotples.hotples.filter(hotple => hotple.taggedBy==null).length : 0}개</div>
        				</div>
						<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('all')}>
          					<div className={styles.pick2}>친구 태그</div>
          					<div className={styles.myhotplenum2}>{myHotples.hotples ? myHotples.hotples.filter(hotple => hotple.taggedBy).length : 0}개</div>
        				</div>
						<div className={styles.myhotpleinfo} onClick={()=>handleHotpleInfoClicked('need_verify')}>
          					<div className={styles.pick2}>검토 필요</div>
          					<div className={styles.myhotplenum2}>{myHotples.hotples ? myHotples.hotples.filter(hotple => hotple.need_verify).length : 0}개</div>
        				</div>
      			</div>
    		</div>);
};

export default UserHeader;
