import { useEffect, useState } from 'react';
import styles from './NoticeModal.module.css';

const NoticeModal = ({ setInfoModalPage, popupNoticeList }) => {
    const [randomNotice, setRandomNotice] = useState(null);

    useEffect(() => {
        if (popupNoticeList && popupNoticeList.length > 0) {
            const randomIndex = Math.floor(Math.random() * popupNoticeList.length);
            setRandomNotice(popupNoticeList[randomIndex]);
        }
    }, [popupNoticeList]);

    const handleDoNotShowFor3Days = () => {
        if (randomNotice) {
            const noticeId = randomNotice.id;
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 3);

            localStorage.setItem(`popup_${noticeId}`, expiryDate.toISOString());

            setInfoModalPage(0);
        }
    };

    const handleContentsClicked = () => {
        if (randomNotice) {
            window.open(randomNotice.notice_url, '_blank', 'noopener,noreferrer');
            setInfoModalPage(0);
        }
    };

    if (!randomNotice) return null;

    return (
        <div className={styles.noticemodal}>
            <img 
                className={styles.contentframeIcon} 
                alt="" 
                src={randomNotice.popup_image_url} 
                onClick={handleContentsClicked} 
            />
            <div className={styles.buttons}>
                <div className={styles.negativeButton} onClick={handleDoNotShowFor3Days}>
                    <div className={styles.text}>3일간 보지않기</div>
                </div>
                <div className={styles.positiveButton} onClick={handleContentsClicked}>
                    <div className={styles.text1}>자세히 보러가기</div>
                </div>
            </div>
        </div>
    );
};

export default NoticeModal;
