import styles from './AddedHotpleItem.module.css';
import LazyLoad from 'react-lazyload';


const AddedHotpleItem = ({hotple, updateHotpleInfo}) => {
	const whenRightButtonClicked = () => {
		if(hotple.need_verify)
			updateHotpleInfo('showVerifyHotpleModal', hotple);
		else
			updateHotpleInfo('showEditHotpleModal', hotple);
	};

  	return (
		<div className={styles.nearbyHotpleItem}>
			<div className={styles.imageframe}>
				{hotple.contents_url.length > 0 &&
					<a className={styles.image2frame} href={hotple.media_url} target="_blank" rel="noopener noreferrer">
						<img className={styles.image1Icon} loading='lazy' alt="" src={hotple.contents_url && hotple.contents_url[0] ? (hotple.contents_url[0].includes("mp4") ? (hotple.preview_url || "images/instagram.svg") : hotple.contents_url[0]) : (hotple.preview_url || "images/instagram.svg")} />
						<div className={styles.infoframe}>
							<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
							<div className={styles.username}>@{hotple.media_creator}</div>
						</div>
					</a>
				}

				{hotple.contents_url.length>2 ?
					<a className={styles.image2frame} href={hotple.media_url} target="_blank" rel="noopener noreferrer">
						<img className={styles.image1Icon} loading='lazy' alt="" src={hotple.contents_url[2]} />
						<div className={styles.infoframe2}>
								<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
								<div className={styles.username}>@{hotple.media_creator}</div>
						</div>
						<div className={styles.infos}>
							<b className={styles.imagenum}>+{hotple.contents_url.length-2}장</b>
							<div className={styles.div}>
								<span className={styles.txt}>
										<p className={styles.p}>게시글</p>
										<p className={styles.p}>보러가기</p>
								</span>
							</div>
						</div>
					</a>
					:
					<a className={styles.image2frame} href={hotple.media_url} target="_blank" rel="noopener noreferrer">
						<img className={styles.image1Icon} loading='lazy' alt="" src={hotple.contents_url && hotple.contents_url[1] ? (hotple.contents_url[1].includes("mp4") ? (hotple.preview_url || "images/instagram.svg") : hotple.contents_url[1]) : (hotple.preview_url || "images/instagram.svg")} />
						<div className={styles.infoframe2}>
								<img className={styles.iconinstagramColor} alt="" src="images/instagram_color.svg" />
								<div className={styles.username}>@{hotple.media_creator}</div>
						</div>
						<div className={styles.infos}>
							<img className={styles.iconshare} alt="" src="images/share.svg" />
							<div className={styles.div}>
								<span className={styles.txt}>
										<p className={styles.p}>게시글</p>
										<p className={styles.p}>보러가기</p>
								</span>
							</div>
						</div>
					</a>
				}
			</div>
			<div className={styles.detailframe}>
					<div className={styles.detailleft}>
						<div className={styles.title}>
								<div className={styles.hotplename}>{hotple.name}</div>
								<div className={styles.ratingframe}>
									<img className={styles.iconfire} alt="" src="images/fire.svg" />
									<div className={styles.ratingtext}>{hotple.mention_count}명이 PICK 했어요!</div>
								</div>
						</div>
						<div className={styles.tags}>
								<div className={styles.tagplaceDetail}>
									<div className={styles.orange}>{hotple.main_category}</div>
								</div>
								<div className={styles.tagplaceDetail1}>
									<div className={styles.orange}>{hotple.sub_category1}</div>
								</div>
								{hotple.tags.map((tag, index) => (
									<div className={styles.tagplaceDetail2} key={index + 1}>
										<div className={styles.orange}>#{tag}</div>
									</div>
								))}
						</div>
						<div className={styles.caption}>{hotple.caption}</div>
						{hotple.comment &&
							<div className={styles.commentframe}>	
									<div className={styles.label}>댓글</div>
									<div className={styles.comment}>
										{hotple.taggedBy!=null &&
											<a className={styles.taggedBy} href={`https://instagram.com/${hotple.taggedBy}`} target="_blank" rel="noopener noreferrer">@{hotple.taggedBy}: </a>
										}
										{hotple.comment.split(/(@[\w\.]+)/g).map((part, index) => {
											if (part.startsWith('@')) {
											// 유저 태그 부분 처리
											const username = part.slice(1); // '@' 제거한 유저 이름
											return (
												<a
												key={index}
												className={styles.commentUser}
												href={`https://instagram.com/${username}`}
												target="_blank"
												rel="noopener noreferrer"
												>
												{part}
												</a>
											);
											} else {
											// 일반 텍스트 처리
											return (
												<span key={index} className={styles.span}>
												{part}
												</span>
											);
											}
										})}
									</div>
							</div>
						}
						{hotple.has_period &&
							<div className={styles.commentframe}>
									<div className={styles.label}>기간</div>
									<div className={styles.period}>{hotple.start_date && hotple.start_date.replaceAll('-','/')} ~ {hotple.end_date && hotple.end_date.replaceAll('-','/')}</div>
							</div>
						}
					</div>
					<div className={styles.detailrightbutton} onClick={(event)=>{event.stopPropagation();whenRightButtonClicked();}}>
						{hotple.need_verify ?
							<img className={styles.iconlikeActive} alt="" src= "images/update.svg" />
							:
							<img className={styles.iconlikeOutlined} alt="" src= "images/pen.svg" />
						}
						<div className={hotple.need_verify ? styles.is_pick : styles.not_pick}> {hotple.need_verify ? "검토 필요" : "정보 수정"} </div>
					</div>
			</div>
		</div>
	);
};

export default AddedHotpleItem;
