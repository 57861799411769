import { BrowserView, MobileView } from 'react-device-detect';
import React, { useEffect, useRef, useState } from 'react';
import LoginHeader from '../../Header/LoginHeader';
import UserHeader from '../../Header/UserHeader';
import LocationBar from '../../LocationBar/LocationBar';
import styles from './HotpleContents.module.css';
import HotpleItem from './HotpleItem/HotpleItem';
import AdminHeader from '../../Header/AdminHeader';
import { forceCheck } from 'react-lazyload';

const Contents = ({ activeMenu, setActiveMenu, setAuthModalPage, setInfoModalPage, notices, account, myHotples, mapHotples, adminHotples, updateHotpleInfo, mapLocation, setIsSearchMode, selectedHotple, setSelectedHotple, selectedOption, setSelectedOption}) => {
	const hotpleRefs = useRef({});
	const [filterText, setFilterText] = useState('');

	const refreshAdminHotple = () => {
		updateHotpleInfo("refreshAdminHotples")
	};

	const deleteFilterTextButtonClicked = () => {
		setFilterText('');
	}

	useEffect(() => {
		if (selectedHotple && hotpleRefs.current[selectedHotple.hotple_id]) {
		hotpleRefs.current[selectedHotple.hotple_id].scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
		}
	}, [selectedHotple]);

	useEffect(() => {
		forceCheck(); 
	}, [mapHotples]);

	return (
		<div className={styles.frame}>
			<BrowserView className={styles.contents}>
				{account.hotpleId == null ? 
					<LoginHeader setAuthModalPage={setAuthModalPage} /> :
					(activeMenu == 'admin' && account.isAdmin ?
						<AdminHeader setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} setSelectedOption={setSelectedOption} notices={notices} account={account} adminHotples={adminHotples} updateHotpleInfo={updateHotpleInfo}/> :
						<UserHeader setAuthModalPage={setAuthModalPage} activeMenu={activeMenu} setActiveMenu={setActiveMenu} setSelectedOption={setSelectedOption} account={account} myHotples={myHotples}/>
					)
				}
				<div className={styles.divider} />
				<LocationBar mapHotples={mapHotples} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode}/>
				<div className={styles.hotpleitems} id="hotpleitems-container">
					{activeMenu!='search' &&
						<div className={filterText.length>0 ? styles.searchfieldActive : styles.searchfield }>
							<div className={styles.div}>
								<img className={filterText.length>0 ? styles.iconsearchActive : styles.iconsearch} alt="" src="images/search.svg" />
							</div>
							<div className={styles.leftContent}>
								<input className={styles.label5}
									type="text"
									value={filterText}
									onChange={(e) => setFilterText(e.target.value)}
									placeholder='이름으로 핫플 필터링'
									autoComplete="off"
								/>
							</div>
							{filterText.length>0 &&
								<div className={styles.div} onClick={deleteFilterTextButtonClicked}>
									<img className={styles.iconsearch2} alt="" src="images/close_filled.svg" />
								</div>
							}
						</div>
					}
					{(activeMenu=='my' || activeMenu=='admin') &&
						<div className={styles.filterbuttons}>
							<div className={selectedOption.filter == 'all' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'all'}))}>
								<div className={styles.label}>전체</div>
							</div>
							<div className={selectedOption.filter == 'need_verify' ? styles.needverifybuttonSelected : styles.needverifybutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'need_verify'}))}>
								<img className={styles.iconupdate} alt="" src="images/update.svg" />
								<div className={styles.label}>검토 필요</div>
							</div>
							{activeMenu=='my' ?
								<div className={selectedOption.filter == 'my_pick' ? styles.mypickbuttonSelected : styles.mypickbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'my_pick'}))}>
									<img className={styles.iconstar} alt="" src="images/star_filled.svg" />
									<div className={styles.label}>내 PICK</div>
								</div>
								:
								<div className={selectedOption.filter == 'edit_request' ? styles.mypickbuttonSelected : styles.mypickbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'edit_request'}))}>
									<img className={styles.iconstar} alt="" src="images/star_filled.svg" />
									<div className={styles.label}>수정 요청</div>
								</div>
							}
							<div className={selectedOption.filter == 'has_period' ? styles.periodbuttonSelected : styles.periodbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'has_period'}))}>
								<img className={styles.iconupdate} alt="" src="images/notification.svg" />
								<div className={styles.label}>기간</div>
							</div>
							{activeMenu=='admin' &&
								<>
									<div className={selectedOption.filter == 'err_image' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'err_image'}))}>
										<div className={styles.label}>사진 {adminHotples.hotples.filter(hotple => !hotple.preview_url || !hotple.contents_url || hotple.contents_url.length==0).length}</div>
									</div>
									<div className={selectedOption.filter == 'err_info' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'err_info'}))}>
										<div className={styles.label}>정보 {adminHotples.hotples.filter(hotple => !hotple.name || !hotple.caption || !hotple.main_category || !hotple.sub_category1 || !hotple.sub_category2 || !hotple.tags || !hotple.place_name || !hotple.address || !hotple.region || !hotple.latitude || !hotple.longitude).length}</div>
									</div>
									<div className={selectedOption.filter == 'err_place' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'err_place'}))}>
										<div className={styles.label}>장소 {adminHotples.hotples.filter(hotple => hotple.address && !((hotple.region == hotple.address.substring(0,2)) || hotple.region == hotple.address.charAt(0) + hotple.address.charAt(2))).length}</div>
									</div>
									<div className={selectedOption.filter == 'err_kakao' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'err_kakao'}))}>
										<div className={styles.label}>카카오맵 {adminHotples.hotples.filter(hotple => !hotple.kakao_map_place_id).length}</div>
									</div>
									<div className={styles.refreshButton} onClick={()=>refreshAdminHotple()}>
										<div className={styles.label}>↻</div>
									</div>
								</>
							}
							{[...new Set(myHotples.hotples.flatMap(hotple => {
								const taggedByUsernames = hotple.taggedBy ? [hotple.taggedBy] : [];
								const commentUsernames = hotple.comment ? (hotple.comment.match(/(@[\w\.]+)/g) || []).map(username => username.substring(1)) : [];

								return [...taggedByUsernames, ...commentUsernames];
							}).filter(username => username && username !== account.instagramUsername))].map(username => (
								<div
									className={selectedOption.filter == username ? styles.usernamebuttonSelected : styles.usernamebutton}
									onClick={() => setSelectedOption(prev => ({ ...prev, filter: username }))}
									key={username}
								>
									<div className={styles.label4}>@{username}</div>
								</div>
							))}
						</div>
					}
					{mapHotples.hotples &&
						mapHotples.hotples
							.filter(hotple => (hotple.name && hotple.name.includes(filterText)) || !hotple.name)
							.map(hotple => (
								<div
									key={hotple.hotple_id}
									ref={el => hotpleRefs.current[hotple.hotple_id] = el}
								>
									<HotpleItem
										key={hotple.hotple_id}
										hotple={hotple}
										activeMenu={activeMenu}
										updateHotpleInfo={updateHotpleInfo}
										setSelectedHotple={setSelectedHotple}
										isSelected={selectedHotple && hotple.hotple_id === selectedHotple.hotple_id}
									/>
								</div>
							))
					}
				</div>
			</BrowserView>

			<MobileView className={styles.contents}>
				{selectedHotple==null && 
					<>
						{account.hotpleId == null ? 
							<LoginHeader setAuthModalPage={setAuthModalPage} /> :
							(activeMenu == 'admin' && account.isAdmin ?
								<AdminHeader setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} setSelectedOption={setSelectedOption} notices={notices} account={account} adminHotples={adminHotples} updateHotpleInfo={updateHotpleInfo}/> :
								<UserHeader setAuthModalPage={setAuthModalPage} activeMenu={activeMenu} setActiveMenu={setActiveMenu} setSelectedOption={setSelectedOption} account={account} myHotples={myHotples}/>
							)
						}
						<div className={styles.divider} />
						<LocationBar mapHotples={mapHotples} mapLocation={mapLocation} setIsSearchMode={setIsSearchMode}/>
					</>
				}
				<div className={styles.hotpleitems} id="hotpleitems-container">
					{activeMenu!='search' && selectedHotple==null &&
						<div className={filterText.length>0 ? styles.searchfieldActive : styles.searchfield }>
							<img className={filterText.length>0 ? styles.iconsearchActive : styles.iconsearch} alt="" src="images/search.svg" />
							<div className={styles.leftContent}>
								<input className={styles.label5}
									type="text"
									value={filterText}
									onChange={(e) => setFilterText(e.target.value)}
									placeholder='이름으로 핫플 필터링'
									autoComplete="off"
								/>
							</div>
							{filterText.length>0 &&
								<div className={styles.div} onClick={deleteFilterTextButtonClicked}>
									<img className={styles.iconsearch2} alt="" src="images/close_filled.svg" />
								</div>
							}
						</div>
					}
					{(activeMenu=='my' || activeMenu=='admin') && selectedHotple == null &&
						<div className={styles.filterbuttons}>
							<div className={selectedOption.filter == 'all' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'all'}))}>
								<div className={styles.label}>전체</div>
							</div>
							<div className={selectedOption.filter == 'need_verify' ? styles.needverifybuttonSelected : styles.needverifybutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'need_verify'}))}>
								<img className={styles.iconupdate} alt="" src="images/update.svg" />
								<div className={styles.label}>검토 필요</div>
							</div>
							{activeMenu=='my' ?
								<div className={selectedOption.filter == 'my_pick' ? styles.mypickbuttonSelected : styles.mypickbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'my_pick'}))}>
									<img className={styles.iconstar} alt="" src="images/star_filled.svg" />
									<div className={styles.label}>내 PICK</div>
								</div>
								:
								<div className={selectedOption.filter == 'edit_request' ? styles.mypickbuttonSelected : styles.mypickbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'edit_request'}))}>
									<img className={styles.iconstar} alt="" src="images/star_filled.svg" />
									<div className={styles.label}>수정 요청</div>
								</div>
							}
							<div className={selectedOption.filter == 'has_period' ? styles.periodbuttonSelected : styles.periodbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'has_period'}))}>
								<img className={styles.iconupdate} alt="" src="images/notification.svg" />
								<div className={styles.label}>기간</div>
							</div>
							{activeMenu=='admin' &&
								<>
									<div className={selectedOption.filter == 'err_image' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'err_image'}))}>
										<div className={styles.label}>사진 {adminHotples.hotples.filter(hotple => !hotple.preview_url || !hotple.contents_url || hotple.contents_url.length==0).length}</div>
									</div>
									<div className={selectedOption.filter == 'err_info' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'err_info'}))}>
										<div className={styles.label}>정보 {adminHotples.hotples.filter(hotple => !hotple.name || !hotple.caption || !hotple.main_category || !hotple.sub_category1 || !hotple.sub_category2 || !hotple.tags || !hotple.place_name || !hotple.address || !hotple.region || !hotple.latitude || !hotple.longitude).length}</div>
									</div>
									<div className={selectedOption.filter == 'err_place' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'err_place'}))}>
										<div className={styles.label}>장소 {adminHotples.hotples.filter(hotple => hotple.address && !((hotple.region == hotple.address.substring(0,2)) || hotple.region == hotple.address.charAt(0) + hotple.address.charAt(2))).length}</div>
									</div>
									<div className={selectedOption.filter == 'err_kakao' ? styles.allbuttonSelected : styles.allbutton} onClick={()=>setSelectedOption((prev)=>({...prev, filter:'err_kakao'}))}>
										<div className={styles.label}>카카오맵 {adminHotples.hotples.filter(hotple => !hotple.kakao_map_place_id).length}</div>
									</div>
									<div className={styles.refreshButton} onClick={()=>refreshAdminHotple()}>
										<div className={styles.label}>↻</div>
									</div>
								</>
							}
							{[...new Set(myHotples.hotples.flatMap(hotple => {
								const taggedByUsernames = hotple.taggedBy ? [hotple.taggedBy] : [];
								const commentUsernames = hotple.comment ? (hotple.comment.match(/(@[\w\.]+)/g) || []).map(username => username.substring(1)) : [];

								return [...taggedByUsernames, ...commentUsernames];
							}).filter(username => username && username !== account.instagramUsername))].map(username => (
								<div
									className={selectedOption.filter == username ? styles.usernamebuttonSelected : styles.usernamebutton}
									onClick={() => setSelectedOption(prev => ({ ...prev, filter: username }))}
									key={username}
								>
									<div className={styles.label4}>@{username}</div>
								</div>
							))}
						</div>
					}
					{selectedHotple==null ?
						mapHotples.hotples
						.filter(hotple => (hotple.name && hotple.name.includes(filterText)) || !hotple.name) // filterText 포함 여부로 필터링
						.map(hotple => (
							<HotpleItem
								key={hotple.hotple_id}
								hotple={hotple}
								activeMenu={activeMenu}
								updateHotpleInfo={updateHotpleInfo}
								setSelectedHotple={setSelectedHotple}
							/>
						))
					:
						<HotpleItem key={selectedHotple.hotple_id} hotple={mapHotples?.hotples?.find(hotple => hotple.hotple_id == selectedHotple?.hotple_id)} activeMenu={activeMenu} updateHotpleInfo={updateHotpleInfo} setSelectedHotple={setSelectedHotple} isSelected={true}/>
					}
				</div>
			</MobileView>
		</div>
		);
};

export default Contents;
