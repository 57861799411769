import styles from './AddedHotpleModal.module.css';
import React, { useState, useEffect } from 'react';
import AddedHotpleItem from './AddedHotpleItem'


const AddedHotpleModal = ({setUserModalPage, modalProps, myHotples, updateHotpleInfo}) => {
	const [myPickHotples, setMyPickHotples] = useState({hotples:[]});
	const [tagHotples, setTagHotples] = useState({hotples:[]});

	useEffect(()=>{
		setMyPickHotples({hotples: modalProps.new_hotples.filter(hotple=>hotple.taggedBy==null)});
		const tagHotples = modalProps.new_hotples
			.filter(hotple => hotple.taggedBy != null)
			.reduce((acc, hotple) => {
				const username = hotple.taggedBy;

				if (!acc[username]) {
				acc[username] = [];
				}

				acc[username].push(hotple);
				return acc;
			}, {});

			setTagHotples(tagHotples);
	},[])

	const handleConfirmButton = async () => {
		setUserModalPage(0);
	};

  	return (
		<div className={styles.addedhotplemodal}>
			<div className={styles.mainframe}>
					<div className={styles.iconlikeFilledParent}>
						<img className={styles.iconlikeFilled} alt="" src="images/like_active.svg" />
						<b className={styles.b}>
								<span>{`새롭게 등록된 `}</span>
								<span className={styles.span}>내 핫플</span>
						</b>
						<img className={styles.iconlikeFilled} alt="" src="images/like_active.svg" />
					</div>
					<div className={styles.myhotpleframe}>
						<div className={styles.myhotpleheader}>
								<div className={styles.pickContainer}>
									<span>{`내가 PICK `}</span>
									<span className={styles.span1}>한 핫플: {myPickHotples.hotples.length}건</span>
								</div>
						</div>
						<div className={styles.contents}>
							{
								myPickHotples.hotples.map(hotple=>(
									myHotples.hotples.find(h => h.hotple_id == hotple.hotple_id) &&
									<AddedHotpleItem key={hotple.hotple_id} hotple={myHotples.hotples.find(h => h.hotple_id == hotple.hotple_id)} updateHotpleInfo={updateHotpleInfo}/>
								))
							}
						</div>
					</div>
					{Object.keys(tagHotples).length >= 1 && (
						<div className={styles.taghotpleframe}>
							{Object.keys(tagHotples).map((username) => (
							<div key={username}>
								{/* 구분선 */}
								<div className={styles.divider} />
								
								{/* 사용자 정보 헤더 */}
								<div className={styles.myhotpleheader}>
								<div className={styles.pickContainer}>
									<a className={styles.username} href={`https://instagram.com/${username}`} target="_blank" rel="noopener noreferrer">@{username}</a>
									<span className={styles.span1}> 님이 태그한 핫플: {tagHotples[username].length}건</span>
								</div>
								</div>

								{/* 핫플 리스트 */}
								<div className={styles.contents}>
								{tagHotples[username].map((hotple) => (
									myHotples.hotples.find(h => h.hotple_id == hotple.hotple_id) &&
									<AddedHotpleItem
									key={hotple.hotple_id}
									hotple={myHotples.hotples.find(h => h.hotple_id == hotple.hotple_id)}
									updateHotpleInfo={updateHotpleInfo}
									/>
								))}
								</div>
							</div>
							))}
						</div>
					)}
			</div>
			<div className={styles.buttonFrame}>
					<div className={styles.confirmButton} onClick={handleConfirmButton}>
						<div className={styles.text}>확인했어요!</div>
					</div>
			</div>
		</div>
	);
};

export default AddedHotpleModal;
