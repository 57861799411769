import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import ko from "date-fns/locale/ko";
import styles from './EditNoticeModal.module.css';
import api from '../../../../api';
import { getImageFile } from '../../../../util';


const EditNoticeModal = ({setInfoModalPage, setNotices}) => {
    const [originalNotices, setOriginalNotices] = useState([]);
    const [editedNotices, setEditedNotices] = useState([]);

    const handleNoticeChanged = (id, key, value) => {
        setEditedNotices((prevNotices) =>
            prevNotices.map((notice) =>
                notice.id === id
                    ? { ...notice, [key]: (key=='start_date' || key == 'end_date' ? (value!=null?format(value, 'yyyy-MM-dd HH:mm'):null) : value) }
                    : notice
            )
        );
    };

    const isNewNoticeAdding = () =>{
        return editedNotices.find(editedNotice => editedNotice.id=='신규');
    };
    
    const isNoticeEdited = (editedNotice) => {
        const originalNotice = originalNotices.find(notice => notice.id === editedNotice.id);
    
        if (!originalNotice)
            return true;
    
        for (let key in editedNotice) {
            if(originalNotice)
            if (editedNotice[key] !== originalNotice[key])
                return true;
        }
    
        return false;
    };

    const isNoticeAvailable = (editedNotice) => {
        if((editedNotice.name == null || editedNotice.name == ''))
            return false;
        if((editedNotice.notice_url == null || editedNotice.notice_url == ''))
            return false;
        if((editedNotice.start_date == null || editedNotice.start_date == ''))
            return false;
        if((editedNotice.end_date == null || editedNotice.end_date == ''))
            return false;

        if(editedNotice.bannerImageFile == null && editedNotice.banner_image_url == null)
            return false;

        return true;
    };

    const handleEditButtonClick = async (editedNotice) => {
        if (!isNoticeAvailable(editedNotice))
            return;

        if(editedNotice.id=='신규'){
            requestAddNewNotice(editedNotice);
        }
        else{
            requestEditNewNotice(editedNotice);
        }
    };

    const requestAddNewNotice = async (editedNotice) => {
        const formData = new FormData();    
    
        formData.append('banner', editedNotice.bannerImageFile);
        if (editedNotice.popupImageFile)
            formData.append('popup', editedNotice.popupImageFile);
        formData.append('name', editedNotice.name);
        formData.append('notice_url', editedNotice.notice_url);
        formData.append('start_date', editedNotice.start_date);
        formData.append('end_date', editedNotice.end_date);
    
        try {
            const response = await api.post('/admin/notice', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 201) {
                const updatedNotice = response.data;
                setEditedNotices((prevNotices) =>
                    prevNotices.map((notice) =>
                        notice.id === "신규" ? updatedNotice : notice
                    )
                );
    
                setOriginalNotices((prevNotices) => [...prevNotices, updatedNotice]);
            }
        } catch (error) {
            console.error('Error updating notice:', error);
        }
    };

    const handleDeleteNoticeButtonClick = async (editedNotice) => {
        try {
            const confirmDelete = window.confirm(`#${editedNotice.id} 배너/팝업을 삭제하시겠습니까?`);
    
            if (confirmDelete) {
                if (editedNotice.id !== '신규')
                    await api.delete(`/admin/notice/${editedNotice.id}`);
    
                setEditedNotices((prevNotices) =>
                    prevNotices.filter((notice) => notice.id !== editedNotice.id)
                );
                setOriginalNotices((prevNotices) =>
                    prevNotices.filter((notice) => notice.id !== editedNotice.id)
                );
            }
        } catch (error) {
            console.error('Error deleting notice:', error);
        }
    };

    const requestEditNewNotice = async (editedNotice) => {
        const formData = new FormData();    
    
        if (editedNotice.bannerImageFile)
            formData.append('banner', editedNotice.bannerImageFile);
        if (editedNotice.popupImageFile)
            formData.append('popup', editedNotice.popupImageFile);
        formData.append('name', editedNotice.name);
        formData.append('notice_url', editedNotice.notice_url);
        formData.append('start_date', editedNotice.start_date);
        formData.append('end_date', editedNotice.end_date);
        formData.append('banner_image_url', editedNotice.banner_image_url);
        formData.append('popup_image_url', editedNotice.popup_image_url);
    
        try {
            const response = await api.patch(`/admin/notice/${editedNotice.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 200) {
                const updatedNotice = response.data;
                setEditedNotices((prevNotices) =>
                    prevNotices.map((notice) =>
                        notice.id === editedNotice.id ? updatedNotice : notice
                    )
                );
    
                setOriginalNotices((prevNotices) =>
                    prevNotices.map((notice) =>
                        notice.id === editedNotice.id ? updatedNotice : notice
                    )
                );
            }
        } catch (error) {
            console.error('Error updating notice:', error);
        }
    };

    const handleGetImageButtonClick = async (editedNotice, type) => {
        try {
            const { filePath, file } = await getImageFile();
            handleNoticeChanged(editedNotice.id, type=='banner'?"bannerImageFile":"popupImageFile", file)
            handleNoticeChanged(editedNotice.id, type=='banner'?"bannerImageFilePath":"popupImageFilePath", filePath)
        } catch (error) {
            console.error('Error selecting file:', error);
        }
    }

    const addNewNotice = () => {
        if(isNewNoticeAdding())
            return;

        const newNotice = {
            id: "신규",
            notice_url: null,
            start_date: null,
            end_date: null,
            name: null
        };
    
        setEditedNotices(prevNotices => [newNotice, ...prevNotices]);
    };

    const isCurrentInPeriod = (editedNotice) => {
        const now = new Date();
        const startDate = new Date(editedNotice.start_date);
        const endDate = new Date(editedNotice.end_date);
    
        return now >= startDate && now <= endDate;
    };

    const handleDeleteImageButtonClick = (editedNotice, type) => {
        if (type=='banner'?"bannerImageFile":"popupImageFile" in editedNotice)
            handleNoticeChanged(editedNotice.id, type=='banner'?"bannerImageFile":"popupImageFile", null)
        if (type=='banner'?"bannerImageFilePath":"popupImageFilePath" in editedNotice)
            handleNoticeChanged(editedNotice.id, type=='banner'?"bannerImageFilePath":"popupImageFilePath", null)
        if (type=='banner'?"banner_image_url":"popup_image_url" in editedNotice)
            handleNoticeChanged(editedNotice.id, type=='banner'?"banner_image_url":"popup_image_url", null)
    }

    const getAllNotices = async () => {
        const response = await api.get(`/admin/notice`);
        const noticeList = response.data;

        setOriginalNotices(noticeList);
        setEditedNotices(noticeList);
    };

    useEffect(() => {
        getAllNotices();
    },[]);

    useEffect(() => {
        setNotices(originalNotices.filter(notice => isCurrentInPeriod(notice)));
    },[originalNotices]);

  	return (
        <div className={styles.editnoticemodal}>
        <div className={styles.contentframe}>
            <div className={styles.headerframe}>
                <b className={styles.b}>배너/팝업 관리</b>
            </div>
            {editedNotices.map(editedNotice =>(
                <div className={styles.innercontentframe} key={editedNotice.id}>
                    <div className={styles.noticeframe}>
                        <div className={styles.titleframe}>
                            <div className={isCurrentInPeriod(editedNotice)?styles.nametext:styles.nametextDisabled}>#{editedNotice.id} - {isCurrentInPeriod(editedNotice)?"활성":"비활성"}</div>
                            <div className={styles.deleteText} onClick={()=>handleDeleteNoticeButtonClick(editedNotice)}>[삭제]</div>
                        </div>
                        <div className={styles.nameframe}>
                            <div className={styles.div}>이름 :</div>
                            <div className={styles.nameinput}>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                    <input
                                        type="text"
                                        id="nameInput1"
                                        className={styles.nameInput1}
                                        value={editedNotice.name}
                                        onChange={(e) => handleNoticeChanged(editedNotice.id, "name", e.target.value)}
                                        placeholder={originalNotices.find(notice => notice.id === editedNotice.id)?.name || ''}
                                        autoComplete="off"
                                    />
                            </div>
                        </div>
                        <div className={styles.nameframe}>
                            <div className={styles.div}>링크 :</div>
                            <div className={styles.nameinput}>
                                <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                <input
                                    type="text"
                                    id="nameInput1"
                                    className={styles.nameInput1}
                                    value={editedNotice.notice_url}
                                    onChange={(e) => handleNoticeChanged(editedNotice.id, "notice_url", e.target.value)}
                                    placeholder={originalNotices.find(notice => notice.id === editedNotice.id)?.notice_url || ''}
                                    autoComplete="off"
                                    />
                            </div>
                        </div>
                        <div className={styles.nameframe}>
                            <div className={styles.div}>배너 :</div>
                            <div className={styles.bannerInput0} onClick={() => handleGetImageButtonClick(editedNotice,"banner")}>
                                <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                <div className={styles.bannerInput}>
                                    {editedNotice.bannerImageFilePath==null ?
                                    (editedNotice.banner_image_url ? "S3 > "+editedNotice.banner_image_url.substring(editedNotice.banner_image_url.indexOf('/notice')) : null):
                                    (editedNotice.bannerImageFilePath ? "PC > "+editedNotice.bannerImageFilePath : null)}
                                </div>
                            </div>
                            <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" onClick={()=>handleDeleteImageButtonClick(editedNotice,'banner')} />
                        </div>
                        <div className={styles.nameframe}>
                            <div className={styles.div}>팝업 :</div>
                            <div className={styles.bannerInput0} onClick={() => handleGetImageButtonClick(editedNotice,"popup")}>
                            <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                <div className={styles.bannerInput}>
                                    {editedNotice.popupImageFilePath==null ?
                                    (editedNotice.popup_image_url ? "S3 > "+editedNotice.popup_image_url.substring(editedNotice.popup_image_url.indexOf('/notice')) : null):
                                    (editedNotice.popupImageFilePath ? "PC > "+editedNotice.popupImageFilePath : null)}
                                </div>
                            </div>
                            <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" onClick={()=>handleDeleteImageButtonClick(editedNotice,'popup')} />
                        </div>
                        <div className={styles.periodframe}>
                                <div className={styles.div1}>기간 :</div>
                                <div className={styles.periodinputframe}>                                          
                                        <DatePicker
                                            className={styles.periodinput1}
                                            selected={editedNotice.start_date ? new Date(editedNotice.start_date.replace(' ', 'T')) : null}
                                            onChange={(date)=>{handleNoticeChanged(editedNotice.id, "start_date", date)}}
                                            showTimeSelect
                                            dateFormat="yyyy/MM/dd HH:mm"
                                            timeFormat="HH:mm"
                                            timeIntervals={30}
                                            locale={ko}
                                            placeholderText="시작일 없음"
                                            onFocus={e => e.target.blur()}
                                            renderCustomHeader={({
                                            date,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                            }) => (
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div className={styles.monthButton} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                        {'<'}
                                                    </div>
                                                    <span className={styles.monthText}>
                                                        {format(date, 'yyyy년 MM월', { locale: ko })}
                                                    </span>
                                                        <div className={styles.monthButton} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                        {'>'}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            withPortal
                                        />

                                        <div className={styles.div8}>~</div>

                                        <DatePicker
                                            className={styles.periodinput1}
                                            selected={editedNotice.end_date ? new Date(editedNotice.end_date.replace(' ', 'T')) : null}
                                            onChange={(date)=>{handleNoticeChanged(editedNotice.id, "end_date", date)}}
                                            showTimeSelect
                                            dateFormat="yyyy/MM/dd HH:mm"
                                            timeFormat="HH:mm"
                                            timeIntervals={30}
                                            locale={ko}
                                            placeholderText="종료일 없음"
                                            onFocus={e => e.target.blur()}
                                            renderCustomHeader={({
                                            date,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                            }) => (
                                                <div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <div className={styles.monthButton} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                        {'<'}
                                                    </div>
                                                    <span className={styles.monthText}>
                                                        {format(date, 'yyyy년 MM월', { locale: ko })}
                                                    </span>
                                                        <div className={styles.monthButton} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                        {'>'}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            withPortal
                                          />
                                    </div>
                              </div>
                        {(editedNotice.banner_image_url || editedNotice.bannerImageFile) &&
                            (editedNotice.bannerImageFile ?
                                <a href={editedNotice.notice_url} target="_blank" rel="noopener noreferrer" key={editedNotice.id}>
                                    <img className={styles.imageframeIcon} loading='lazy' alt="" src={URL.createObjectURL(editedNotice.bannerImageFile) || ''} />
                                </a>
                                :
                                <a href={editedNotice.notice_url} target="_blank" rel="noopener noreferrer" key={editedNotice.id}>
                                    <img className={styles.imageframeIcon} loading='lazy' alt="" src={editedNotice.banner_image_url || ''} />
                                </a>
                            )
                        }
                        {isNoticeEdited(editedNotice) &&
                            <div className={isNoticeAvailable(editedNotice) ? styles.editButton : styles.editButtonDisabled} onClick={()=>handleEditButtonClick(editedNotice)}>
                                <div className={styles.nametext}>변경 사항 적용</div>
                            </div>        
                        }                
                    </div>
                </div>
            )
        )}
        </div>
        <div className={styles.buttonFrame}>
            <div className={styles.backButton} onClick={()=>setInfoModalPage(0)}>
                <div className={styles.nametext}>돌아가기</div>
            </div>
            <div className={isNewNoticeAdding()?styles.addButtonDisabled:styles.addButton} onClick={addNewNotice}>
                <div className={styles.nametext}>배너/팝업 추가</div>
            </div>
        </div>
    </div>
    );
};

export default EditNoticeModal;
            