import styles from './Menu.module.css';

const Menu = ({activeMenu, setActiveMenu, isAdmin}) => {
  return (
    <div className={styles.menu}>
      <div className={styles.logo}>
        <img className={styles.iconfire} alt="" src="images/fire.svg" />
      </div>
      <div className={styles.buttons}>
        <div
          className={`${styles.button1} ${activeMenu === 'home' ? styles.active : ''}`}
          onClick={() => setActiveMenu('home')}
        >
          <img className={styles.icon} alt="" src="images/home_outlined.svg" />
          <div className={styles.div1}>홈</div>
        </div>

        <div
          className={`${styles.button} ${activeMenu === 'search' ? styles.active : ''}`}
          onClick={() => setActiveMenu('search')}
        >
          <img className={styles.icon} alt="" src="images/search.svg" />
          <div className={styles.div}>핫플찾기</div>
        </div>

        <div
          className={`${styles.button1} ${activeMenu === 'my' ? styles.active : ''}`}
          onClick={() => setActiveMenu('my')}
        >
          <img className={styles.icon} alt="" src="images/like_outlined.svg" />
          <div className={styles.div1}>내 핫플</div>
        </div>

        {isAdmin &&
          <div
            className={`${styles.button1} ${activeMenu === 'admin' ? styles.active : ''}`}
            onClick={() => setActiveMenu('admin')}
          >
            <img className={styles.icon} alt="" src="images/setting.svg" />
            <div className={styles.div1}>관리자</div>
          </div>
        }
      </div>
    </div>
  );
};

export default Menu;