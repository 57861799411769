import { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import ko from "date-fns/locale/ko";
import styles from './EditPeriodCategoryModal.module.css';
import api from '../../../../api';
import { getImageFile } from '../../../../util';

const EditPeriodCategoryModal = ({ setInfoModalPage }) => {
    const [originalGroups, setOriginalGroups] = useState([]);
    const [editedGroups, setEditedGroups] = useState([]);
    const [focusGroup, setFocusGroup] = useState(null);
    const [query, setQuery] = useState('');
    const [hotples, setHotples] = useState([]);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        let id;

        const isKoreanSyllable = (char) => {
            const code = char.charCodeAt(0);
            return code >= 0xAC00 && code <= 0xD7A3;
        };
    
        const isValidQuery = (query) => {
            if(query[query.length - 1] == ' ')
                return false;

            for (let char of query) {
                if (!isKoreanSyllable(char) && /[ㄱ-ㅎㅏ-ㅣ]/.test(char)) {
                    return false;
                }
            }
            return true;
        };
    
        if (query) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
    
            if (isValidQuery(query)) {
                id = setTimeout(() => {
                    fetchHotples(query);
                }, 200);
            }
    
            setTimeoutId(id);
        } else {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setHotples([]);
        }
    
        return () => {
            if (id) {
                clearTimeout(id);
            }
        };
    }, [query]);

    const fetchHotples = async (keyword) => {
        const response = await api.get(`/hotple/search`, { params: { keyword } });
        setHotples(response.data);
    };

    const isCurrentInPeriod = (group) => {
        const now = new Date();
        const startDate = new Date(group.start_date);
        const endDate = new Date(group.end_date);
    
        return now >= startDate && now <= endDate;
    };

    const handleGroupChange = (id, key, value) => {
        setEditedGroups((prevGroups) =>
            prevGroups.map((group) =>
                group.id === id
                    ? { ...group, [key]: (key === 'start_date' || key === 'end_date' ? (value ? format(value, 'yyyy-MM-dd HH:mm') : null) : value) }
                    : group
            )
        );
    };

    const handleHotpleSelect = (groupId, hotple) => {
        setEditedGroups((prevGroups) =>
            prevGroups.map((group) =>
                group.id === groupId
                    ? {
                        ...group,
                        hotple_ids: [...new Set([...(group.hotple_ids || []), hotple.id])],
                        hotple_names: [...new Set([...(group.hotple_names || []), hotple.name])],
                    }
                    : group
            )
        );
        console.log(editedGroups);
    };

    const handleHotpleRemove = (groupId, hotpleId) => {
        setEditedGroups((prevGroups) =>
            prevGroups.map((group) =>
                group.id === groupId
                    ? {
                        ...group,
                        hotple_ids: group.hotple_ids.filter(id => id !== hotpleId),
                        hotple_names: group.hotple_names ? group.hotple_names.filter((_, index) => group.hotple_ids[index] !== hotpleId) : []
                    }
                    : group
            )
        );
    };

    const isNewGroupAdding = () => editedGroups.some(group => group.id === '신규');

    const isGroupEdited = (editedGroup) => {
        const originalGroup = originalGroups.find(group => group.id === editedGroup.id);
        if (!originalGroup) return true;

        for (let key in editedGroup) {
            if (editedGroup[key] != originalGroup[key]){
                console.log(`${key}: ${originalGroup[key]}-${editedGroup[key]}`)
                return true;
            }
        }
        return false;
    };

    const isGroupValid = (editedGroup) => {
        return editedGroup.name && editedGroup.start_date && editedGroup.end_date && (editedGroup.button_image_url || editedGroup.button_image);
    };

    const handleSaveButtonClick = async (editedGroup) => {
        if (!isGroupValid(editedGroup)) return;

        if (editedGroup.id === '신규') {
            await requestAddNewGroup(editedGroup);
        } else {
            await requestEditGroup(editedGroup);
        }
    };

    const requestAddNewGroup = async (editedGroup) => {
        const formData = new FormData();  

        formData.append('name', editedGroup.name);
        formData.append('hotple_ids', `{${editedGroup.hotple_ids.join(',')}}`);
        formData.append('start_date', editedGroup.start_date);
        formData.append('end_date', editedGroup.end_date);
        formData.append('button_image', editedGroup.button_image);

        try {
            const response = await api.post('/admin/hotple/hotple_groups', formData);
            if (response.status === 201) {
                const newGroup = response.data;
                setEditedGroups((prevGroups) =>
                    prevGroups.map((group) => group.id === '신규' ? newGroup : group)
                );
                setOriginalGroups((prevGroups) => [...prevGroups, newGroup]);
            }
        } catch (error) {
            console.error('Error adding group:', error);
        }
    };

    const handleDeleteGroupButtonClick = async (editedGroup) => {
        const confirmDelete = window.confirm(`#${editedGroup.id} 그룹을 삭제하시겠습니까?`);
        if (!confirmDelete) return;

        try {
            if (editedGroup.id !== '신규') await api.delete(`/admin/hotple/hotple_groups/${editedGroup.id}`);

            setEditedGroups((prevGroups) => prevGroups.filter((group) => group.id !== editedGroup.id));
            setOriginalGroups((prevGroups) => prevGroups.filter((group) => group.id !== editedGroup.id));
        } catch (error) {
            console.error('Error deleting group:', error);
        }
    };

    const requestEditGroup = async (editedGroup) => {
        const formData = new FormData();  

        formData.append('name', editedGroup.name);
        formData.append('hotple_ids', `{${editedGroup.hotple_ids.join(',')}}`);
        formData.append('start_date', editedGroup.start_date);
        formData.append('end_date', editedGroup.end_date);
        if (editedGroup.button_image)
            formData.append('button_image', editedGroup.button_image);

        try {
            const response = await api.patch(`/admin/hotple/hotple_groups/${editedGroup.id}`, formData);
            if (response.status === 200) {
                const updatedGroup = response.data;
                setEditedGroups((prevGroups) =>
                    prevGroups.map((group) => group.id === editedGroup.id ? updatedGroup : group)
                );
                setOriginalGroups((prevGroups) =>
                    prevGroups.map((group) => group.id === editedGroup.id ? updatedGroup : group)
                );
            }
        } catch (error) {
            console.error('Error editing group:', error);
        }
    };

    const addNewGroup = () => {
        if (isNewGroupAdding()) return;

        const newGroup = {
            id: "신규",
            name: "",
            button_image_url: "",
            hotple_ids: [],
            hotple_names: [],
            start_date: null,
            end_date: null,
        };
        setEditedGroups(prevGroups => [newGroup, ...prevGroups]);
    };

    const handleGetImageButtonClick = async (group) => {
        try {
            const { filePath, file } = await getImageFile();
            handleGroupChange(group.id, "button_image", file)
            handleGroupChange(group.id, "button_image_path", filePath)
        } catch (error) {
            console.error('Error selecting file:', error);
        }
    }

    const getAllGroups = async () => {
        const response = await api.get(`/admin/hotple/hotple_groups`);
        setOriginalGroups(response.data);
        setEditedGroups(response.data);
    };

    useEffect(() => {
        getAllGroups();
    }, []);

    return (
        <div className={styles.editPeriodCategoryModal}>
            <div className={styles.contentFrame}>
                <div className={styles.headerFrame}>
                    <b className={styles.b}>핫플 그룹 관리</b>
                </div>
                {editedGroups.map((group) => (
                    <div className={styles.innerContentFrame} key={group.id}>
                        <div className={styles.groupFrame}>
                            <div className={styles.titleFrame}>
                                <div className={isCurrentInPeriod(group) ? styles.nameText : styles.nameTextDisabled}>
                                    #{group.id} - {isCurrentInPeriod(group) ? "활성" : "비활성"}
                                </div>
                                <div className={styles.deleteText} onClick={() => handleDeleteGroupButtonClick(group)}>[삭제]</div>
                            </div>
                            <div className={styles.nameFrame}>
                                <div className={styles.div}>이름 :</div>
                                <div className={styles.nameinput}>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                    <input
                                        type="text"
                                        className={styles.nameInput1}
                                        value={group.name}
                                        onChange={(e) => handleGroupChange(group.id, "name", e.target.value)}
                                        placeholder={originalGroups.find((g) => g.id === group.id)?.name || ''}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className={styles.nameFrame}>
                                <div className={styles.div}>핫플 :</div>
                                <div className={styles.nameinput}>
                                    <img className={styles.iconpen} alt="" src="images/search.svg" />
                                    <input
                                        type="text"
                                        className={styles.nameInput1}
                                        value={focusGroup == group.id?query:''}
                                        onChange={(e) => setQuery(e.target.value)}
                                        onFocus={() => setFocusGroup(group.id)}
                                        onBlur={() => {setFocusGroup(null);setQuery('');}}
                                        placeholder="검색할 핫플 이름 입력"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            {focusGroup == group.id && hotples && hotples.length>0 && (
                                <div className={styles.searchResults}>
                                    {hotples.map((hotple) => (
                                        <div
                                            key={hotple.id}
                                            className={styles.searchResultItem}
                                            onMouseDown={() => handleHotpleSelect(group.id, hotple)}
                                        >
                                            {hotple.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {group.hotple_names && group.hotple_names.length>0 &&
                                <div className={styles.selectedHotpleList}>
                                    <div className={styles.selectedHotpleNum}>
                                        <span>총 {group.hotple_names.length}개</span>
                                    </div>
                                    {group.hotple_names.map((name, index) => (
                                        <div key={index} className={styles.selectedHotple}>
                                            <span>{name}</span>
                                            <img className={styles.removeButton} alt="" src="images/close_line.svg" onClick={() => handleHotpleRemove(group.id, group.hotple_ids[index])}/>
                                        </div>
                                    ))}
                                </div>
                            }
                            <div className={styles.nameFrame}>
                                <div className={styles.div}>버튼 :</div>
                                <div className={styles.nameinput2} onClick={() => handleGetImageButtonClick(group)}>
                                    <img className={styles.iconpen} alt="" src="images/search.svg" />
                                    <div>
                                        {group.button_image_path==null ?
                                        (group.button_image_url ? "S3 > "+group.button_image_url.substring(group.button_image_url.indexOf('/hotple_group')) : null):
                                        (group.button_image_path ? "PC > "+group.button_image_path : null)}
                                    </div>
                                </div>
                            </div>
                            {(group.button_image || group.button_image_url) &&
                                (group.button_image ?
                                    <div className={styles.buttonImageFrame}>
                                        <img className={styles.buttonImage} loading='lazy' alt="" src={URL.createObjectURL(group.button_image) || ''} />
                                    </div>
                                    :
                                    <div className={styles.buttonImageFrame}>
                                        <img className={styles.buttonImage} loading='lazy' alt="" src={group.button_image_url || ''} />
                                    </div>
                                )
                            }
                            <div className={styles.periodFrame}>
                                <div className={styles.div1}>기간 :</div>
                                <div className={styles.periodInputFrame}>
                                    <DatePicker
                                        className={styles.periodInput1}
                                        selected={group.start_date ? new Date(group.start_date.replace(' ', 'T')) : null}
                                        onChange={(date) => handleGroupChange(group.id, "start_date", date)}
                                        showTimeSelect
                                        dateFormat="yyyy/MM/dd HH:mm"
                                        locale={ko}
                                        placeholderText="시작일 없음"
                                        withPortal
                                    />
                                    <div className={styles.div8}>~</div>
                                    <DatePicker
                                        className={styles.periodInput1}
                                        selected={group.end_date ? new Date(group.end_date.replace(' ', 'T')) : null}
                                        onChange={(date) => handleGroupChange(group.id, "end_date", date)}
                                        showTimeSelect
                                        dateFormat="yyyy/MM/dd HH:mm"
                                        locale={ko}
                                        placeholderText="종료일 없음"
                                        withPortal
                                    />
                                </div>
                            </div>
                            {isGroupEdited(group) && (
                                <div
                                    className={isGroupValid(group) ? styles.editButton : styles.editButtonDisabled}
                                    onClick={() => handleSaveButtonClick(group)}
                                >
                                    <div className={styles.nameText}>변경 사항 적용</div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.buttonFrame}>
                <div className={styles.backButton} onClick={() => setInfoModalPage(0)}>
                    <div className={styles.nameText}>돌아가기</div>
                </div>
                <div
                    className={isNewGroupAdding() ? styles.addButtonDisabled : styles.addButton}
                    onClick={addNewGroup}
                >
                    <div className={styles.nameText}>핫플 그룹 추가</div>
                </div>
            </div>
        </div>
    );
};

export default EditPeriodCategoryModal;
