// src/App.js
import React, { useEffect } from 'react';
import MainFrame from './MainFrame';
import ReactGA from 'react-ga4';
import './App.css';

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="App">
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <MainFrame />
    </div>
  );
}

export default App;
