import styles from './AuthModal.module.css';
import LoginModal from './Login/LoginModal';
import RegisterModal from './Register/RegisterModal';
import ConnectInstagramModal from './ConnectInstagram/ConnectInstagramModal';
import LogoutModal from './Logout/LogoutModal';


const AuthModal = ({authModalPage, setAuthModalPage, setInfoModalPage, setModalProps, getMyInfo, setAccount}) => {
    const goPrevPage = () => {
        setAuthModalPage(authModalPage-1);
    };

    const goNextPage = () => {
        setAuthModalPage(authModalPage+1);
    }

  	return (
        <div className={styles.modalbackground}>
            <div className={styles.modalcontainer}>
                {authModalPage==1 &&<LoginModal setAuthModalPage={setAuthModalPage} getMyInfo={getMyInfo}/>}
                {authModalPage==2 &&<RegisterModal setAuthModalPage={setAuthModalPage} setInfoModalPage={setInfoModalPage} setModalProps={setModalProps} getMyInfo={getMyInfo}/>}
                {authModalPage==3 &&<ConnectInstagramModal/>}
                {authModalPage==4 && <LogoutModal setAuthModalPage={setAuthModalPage} setAccount={setAccount}/>}

                <div className={styles.closebutton} onClick={()=>setAuthModalPage(0)}>
                    <img className={styles.iconcloseLine} alt="" src="images/close_line.svg" />
                </div>
                {authModalPage==2 &&
                    <div className={styles.backbutton} onClick={goPrevPage}>
                        <img className={styles.iconcloseLine} alt="" src="images/back.svg" />
                    </div>
                }
            </div>
        </div>
    );
};
    
export default AuthModal;
  