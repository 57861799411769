import api from '../../../../api';
import styles from './UserMonitorModal.module.css';
import React, { useEffect, useState } from 'react';

const UserMonitorModal = ({ setInfoModalPage }) => {
    const [users, setUsers] = useState({ hotpleUsers: [], instagramUsers: [] });
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortColumn, setSortColumn] = useState('');
    const [filters, setFilters] = useState({ id: '', user_id: '', instagram_username: '', hotple_ids: '', instagram_hotple_ids: '' });

    const fetchAllUsers = async () => {
        try {
            const response = await api.get('/admin/users');
            if (response.status === 200) {
                setUsers(response.data);
                setFilteredUsers(response.data.hotpleUsers);
            } else {
                console.error('Failed to fetch users');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const clearFilter = (column) => {
        const newFilters = { ...filters, [column]: '' };
        setFilters(newFilters);
        handleFilterChange({ target: { value: '' } }, column);
    };

    useEffect(() => {
        fetchAllUsers();
    }, []);

    const findInstagramHotpleIds = (instagramUsername) => {
        const instagramUser = users.instagramUsers.find(user => user.username === instagramUsername);
        return instagramUser ? instagramUser.hotple_ids : [];
    };

    // 테이블 정렬 처리 함수
    const handleSort = (column) => {
        const newSortOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        setSortColumn(column);
    
        const sortedUsers = [...filteredUsers].sort((a, b) => {
            if (column === 'hotple_length' || column === 'instagram_hotple_length') {
                // 핫플 수(일반), 핫플 수(멘션) 정렬
                const aLength = column === 'hotple_length'
                    ? (a.hotple_ids && a.hotple_ids.length) || 0  // undefined 체크 후 length
                    : (findInstagramHotpleIds(a.instagram_username).length) || 0;
                const bLength = column === 'hotple_length'
                    ? (b.hotple_ids && b.hotple_ids.length) || 0  // undefined 체크 후 length
                    : (findInstagramHotpleIds(b.instagram_username).length) || 0;
                return newSortOrder === 'asc' ? aLength - bLength : bLength - aLength;
            } else if (column === 'created_at' || column === 'updated_at') {
                // 가입일 기준 정렬
                const dateA = new Date(a[column]);
                const dateB = new Date(b[column]);
                return newSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
            } else {
                // 그 외의 컬럼 기준 정렬
                if (a[column] < b[column]) return newSortOrder === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return newSortOrder === 'asc' ? 1 : -1;
                return 0;
            }
        });
    
        setFilteredUsers(sortedUsers);
    };
    

    // 필터링 처리 함수
    const handleFilterChange = (e, column) => {
        const newFilters = { ...filters, [column]: e.target.value };
        setFilters(newFilters);
    
        const filtered = users.hotpleUsers.filter(user => {
            const idMatch = newFilters.id
                ? user.id.toString() === newFilters.id // 정확히 일치하는 값만 필터링
                : true;
    
            const hotpleIdsMatch = column === 'hotple_ids'
                ? newFilters.hotple_ids === '-' 
                    ? user.hotple_ids.length === 0 
                    : newFilters.hotple_ids.split(',').every(id => user.hotple_ids.includes(parseInt(id.trim())))
                : true;
    
            const instagramHotpleIdsMatch = column === 'instagram_hotple_ids'
                ? newFilters.instagram_hotple_ids === '-' 
                    ? findInstagramHotpleIds(user.instagram_username).length === 0 
                    : newFilters.instagram_hotple_ids.split(',').every(id => findInstagramHotpleIds(user.instagram_username).includes(parseInt(id.trim())))
                : true;
    
            const instagramUsernameMatch = column === 'instagram_username'
                ? newFilters.instagram_username === '-'
                    ? user.instagram_username === null
                    : user.instagram_username?.toLowerCase().includes(newFilters.instagram_username.toLowerCase())
                : true;
    
            return (
                idMatch &&
                user.user_id.toLowerCase().includes(newFilters.user_id.toLowerCase()) &&
                instagramUsernameMatch &&
                hotpleIdsMatch &&
                instagramHotpleIdsMatch
            );
        });
    
        // 필터가 비어 있으면 전체 데이터 다시 보여줌
        if (Object.values(newFilters).every(value => value === '')) {
            setFilteredUsers(users.hotpleUsers);
        } else {
            setFilteredUsers(filtered);
        }
    };
    
    

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.contents}>
                    <b className={styles.title}>
                        <p className={styles.p1}>
                            <span>유저 관리</span>
                        </p>
                        <table className={styles.statisticTable}>
                            <thead>
                                <tr>
                                    <td>총 유저</td>
                                    <td>신규 유저 수</td>
                                    <td>평균 핫플 수 (일반)</td>
                                    <td>평균 핫플 수 (멘션)</td>
                                    <td>평균 검토 수</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{users.hotpleUsers.length}명</td>
                                    <td>
                                        {users.hotpleUsers.filter(user => {
                                            const oneMonthAgo = new Date();
                                            oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
                                            return new Date(user.created_at) > oneMonthAgo;
                                        }).length}명
                                    </td>
                                    <td>
                                        {(users.hotpleUsers.reduce((sum, user) => sum + user.hotple_ids.length, 0) / users.hotpleUsers.length).toFixed(1)}개
                                    </td>
                                    <td>
                                        {(users.hotpleUsers.reduce((sum, user) => sum + findInstagramHotpleIds(user.instagram_username).length, 0) / users.hotpleUsers.length).toFixed(1)}개
                                    </td>
                                    <td>
                                        {(users.hotpleUsers.reduce((sum, user) => sum + user.verify_count, 0) / users.hotpleUsers.length).toFixed(1)}개
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </b>

                    <div className={styles.tableFrame}>
                        <div className={styles.userTableFrame}>
                            <div className={styles.dataCount}>데이터 수: {filteredUsers.length}개</div>
                            <table className={styles.userTable}>
                                <thead>
                                    <tr>
                                        <th className={styles.id} onClick={() => handleSort('id')}>#</th>
                                        <th className={styles.ids} onClick={() => handleSort('user_id')}>핫플 ID</th>
                                        <th className={styles.ids} onClick={() => handleSort('instagram_username')}>인스타 ID</th>
                                        <th className={styles.hotples} onClick={() => handleSort('hotple_length')}>핫플(일반)</th>
                                        <th className={styles.hotples} onClick={() => handleSort('instagram_hotple_length')}>핫플(멘션)</th>
                                        <th className={styles.count} onClick={() => handleSort('hotple_length')}>핫플 수<br/>(일반)</th>
                                        <th className={styles.count} onClick={() => handleSort('instagram_hotple_length')}>핫플 수<br/>(멘션)</th>
                                        <th className={styles.count} onClick={() => handleSort('verify_count')}>검토 수</th>
                                        <th className={styles.date} onClick={() => handleSort('created_at')} >가입</th>
                                        <th className={styles.date} onClick={() => handleSort('updated_at')} >최근활동</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className={styles.id}>
                                            <div className={styles.filterInputWrapper}>
                                                <input type="text" value={filters.id} onChange={(e) => handleFilterChange(e, 'id')} />
                                                {filters.id && (
                                                    <img 
                                                        src="/images/close_line.svg" 
                                                        alt="Clear" 
                                                        className={styles.clearButton} 
                                                        onClick={() => clearFilter('id')} 
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th className={styles.ids}>
                                            <div className={styles.filterInputWrapper}>
                                                <input type="text" value={filters.user_id} onChange={(e) => handleFilterChange(e, 'user_id')} />
                                                {filters.user_id && (
                                                    <img 
                                                        src="/images/close_line.svg" 
                                                        alt="Clear" 
                                                        className={styles.clearButton} 
                                                        onClick={() => clearFilter('user_id')} 
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th className={styles.ids}>
                                            <div className={styles.filterInputWrapper}>
                                                <input type="text" value={filters.instagram_username} onChange={(e) => handleFilterChange(e, 'instagram_username')} />
                                                {filters.instagram_username && (
                                                    <img 
                                                        src="/images/close_line.svg" 
                                                        alt="Clear" 
                                                        className={styles.clearButton} 
                                                        onClick={() => clearFilter('instagram_username')} 
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th className={styles.hotples}>
                                            <div className={styles.filterInputWrapper}>
                                                <input type="text" value={filters.hotple_ids} onChange={(e) => handleFilterChange(e, 'hotple_ids')} />
                                                {filters.hotple_ids && (
                                                    <img 
                                                        src="/images/close_line.svg" 
                                                        alt="Clear" 
                                                        className={styles.clearButton} 
                                                        onClick={() => clearFilter('hotple_ids')} 
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th className={styles.hotples}>
                                            <div className={styles.filterInputWrapper}>
                                                <input type="text" value={filters.instagram_hotple_ids} onChange={(e) => handleFilterChange(e, 'instagram_hotple_ids')} />
                                                {filters.instagram_hotple_ids && (
                                                    <img 
                                                        src="/images/close_line.svg" 
                                                        alt="Clear" 
                                                        className={styles.clearButton} 
                                                        onClick={() => clearFilter('instagram_hotple_ids')} 
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th className={styles.count}></th>
                                        <th className={styles.count}></th>
                                        <th className={styles.count}></th>
                                        <th className={styles.date}></th>
                                        <th className={styles.date}></th>
                                    </tr>

                                    {filteredUsers.map(user => (
                                        <tr key={user.id}>
                                            <td className={styles.id}>{user.id}</td>
                                            <td className={styles.ids}>{user.user_id}</td>
                                            <td className={styles.ids}>
                                                {user.instagram_username ?
                                                    <a className={styles.instagramUsername} href={`https://instagram.com/${user.instagram_username}/`} target="_blank" rel="noopener noreferrer">@{user.instagram_username}</a>
                                                :
                                                    <>-</>
                                                }
                                            </td>
                                            <td className={styles.hotples}>
                                                {user.hotple_ids.length > 0 ? (
                                                    user.hotple_ids.map((hotpleId, index) => (
                                                        <a
                                                            key={hotpleId}
                                                            className={styles.instagramUsername}
                                                            href={`https://hotple.today/?menu=search&hotple_id=${hotpleId}/`} target="_blank" rel="noopener noreferrer"
                                                        >
                                                            {hotpleId}{index < user.hotple_ids.length - 1 && ', '}
                                                        </a>
                                                    ))
                                                ) :
                                                    "-"
                                                }
                                            </td>
                                            <td className={styles.hotples}>
                                                {(() => {
                                                    const instagramHotpleIds = findInstagramHotpleIds(user.instagram_username);
                                                    return instagramHotpleIds.length > 0 ? (
                                                        instagramHotpleIds.map((hotpleId, index) => (
                                                            <a
                                                                key={hotpleId}
                                                                className={styles.instagramUsername}
                                                                href={`https://hotple.today/?menu=search&hotple_id=${hotpleId}/`} 
                                                                target="_blank" 
                                                                rel="noopener noreferrer"
                                                            >
                                                                {hotpleId}{index < instagramHotpleIds.length - 1 && ', '}
                                                            </a>
                                                        ))
                                                    ) :
                                                        "-"
                                                })()}
                                            </td>
                                            <td className={styles.count}>{user.hotple_ids.length}</td>
                                            <td className={styles.count}>{findInstagramHotpleIds(user.instagram_username).length}</td>
                                            <td className={styles.count}>{user.verify_count}</td>
                                            <td className={styles.date}>{new Date(user.created_at).toLocaleString()}</td>
                                            <td className={styles.date}>{new Date(user.updated_at).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.buttonFrame}>
                <div className={styles.confirmButton} onClick={()=>setInfoModalPage(0)}>
                    <div className={styles.text}>확인했어요!</div>
                </div>
            </div>
        </div>
    );
};

export default UserMonitorModal;
