import styles from './EditRequestHotpleModal.module.css';
import api from '../../../../api'
import { useEffect, useState } from 'react';
import DetailHeader from '../DetailHeader/DetailHeader';



const EditRequestHotpleModal = ({setHotpleModalPage, editedHotple, updateHotpleField, originalHotple, updateHotpleInfo}) => {
      const [verifyResults, setVerifyResults] = useState([]);

      const handleNextButtonClick = async () => {
            try {
                  let hotple = {};

                  Object.keys(editedHotple).forEach((key) => {
                  if (editedHotple[key] != originalHotple[key])
                        hotple[key] = editedHotple[key];
                  });

                  hotple['hotple_id']=originalHotple.hotple_id;
                  if('comment' in hotple)
                        hotple['comment_id']=originalHotple.comment_id;
            
                  const response = await api.post('/hotple/verify', {
                        hotple,
                        needCheck: false,
                        verifyResults: originalHotple.verify_results.map(verifyResult => verifyResult.id)
                  });

                  setHotpleModalPage(0);
                  updateHotpleInfo('updateHotpleInfoFromList', response.data.hotples[0]);
            } catch (error) {
                  console.error("Error in handleNextButtonClick:", error);
            }
      };

      useEffect(() => {
            if(originalHotple.verify_results)
                  setVerifyResults(originalHotple.verify_results.filter(verify_result => verify_result.need_check));
      },[originalHotple]);

      return (
            <div className={styles.reviewmodal4}>
                  <div className={styles.mainframe}>
                        <div className={styles.headerframe}>
                              <b className={styles.b}>더 적절한 정보를 선택해주세요!</b>
                              <a className={styles.instagrambutton} href={editedHotple.media_url} target="blank" rel="noopener noreferrer">
                                    게시글 확인하고 오기 →
                              </a>

                              {originalHotple.media_caption &&
                                    <DetailHeader hotple={editedHotple} updateHotpleField={updateHotpleField} updateHotpleInfo={updateHotpleInfo}/>
                              }
                        </div>
                        <div className={styles.mainframe1}>
                              <div className={styles.nameframe} onClick={()=>setHotpleModalPage(22)}>
                                    <div className={styles.nametext}>{editedHotple.name}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              {verifyResults.some(verifyResult => verifyResult.name) &&
                                    <div className={styles.captionframe}>
                                          <div className={styles.commentdivTaggedBy}> ▶</div>
                                          {verifyResults.map(verifyResult => verifyResult.name).map((name, index) => (
                                                name &&
                                                <div className={styles.nameframe}  key={index} onClick={()=>updateHotpleField("name",name)}>
                                                      <div className={styles.nametext}>{name}</div>
                                                </div>
                                          ))}
                                    </div>
                              }

                              <div className={styles.categoryframe} onClick={()=>setHotpleModalPage(22)}>
                                    <div className={styles.tagplaceDetail}>
                                          <div className={styles.maincategorytext}>{editedHotple.main_category}</div>
                                    </div>
                                    <div className={styles.tagplaceDetail1} >
                                          <div className={styles.maincategorytext}>{`${editedHotple.sub_category1} > ${editedHotple.sub_category2}`}</div>
                                    </div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              {verifyResults.some(verifyResult => verifyResult.main_category || verifyResult.sub_category1 || verifyResult.sub_category2) &&
                                    <div className={styles.categoryframe}>
                                          <div className={styles.commentdivTaggedBy}> ▶</div>
                                          {verifyResults.map(verifyResult => verifyResult.main_category).map((main_category, index) => (
                                                main_category &&
                                                <div className={styles.tagplaceDetail} key={index} onClick={()=>updateHotpleField("main_category",main_category)}>
                                                      <div className={styles.maincategorytext}>{main_category}</div>
                                                </div>
                                          ))}
                                          {verifyResults.map((verifyResult, index) => {
                                                const subCategory1 = verifyResult.sub_category1 || originalHotple.sub_category1;
                                                const subCategory2 = verifyResult.sub_category2 || originalHotple.sub_category2;

                                                return ((verifyResult.sub_category1 || verifyResult.sub_category2) &&
                                                      <div key={index+1} className={styles.tagplaceDetail1} onClick={()=>{updateHotpleField("sub_category1",subCategory1);updateHotpleField('sub_category2',subCategory2);}}>
                                                            <div className={styles.maincategorytext}>{`${subCategory1} > ${subCategory2}`}</div>
                                                      </div>
                                                )
                                          })}
                                    </div>
                              }

                              <div className={styles.tagsframe} onClick={()=>setHotpleModalPage(23)}>
                                    <div className={styles.tags1}>
                                          <div className={styles.maincategorytext}>#{editedHotple.tags && editedHotple.tags[0]}</div>
                                    </div>
                                    <div className={styles.tags1}>
                                          <div className={styles.maincategorytext}>#{editedHotple.tags && editedHotple.tags[1]}</div>
                                    </div>
                                    <div className={styles.tags1}>
                                          <div className={styles.maincategorytext}>#{editedHotple.tags && editedHotple.tags[2]}</div>
                                    </div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              {verifyResults.some(verifyResult => verifyResult.tags) &&
                                    <div className={styles.tagsframe}>
                                          <div className={styles.commentdivTaggedBy}> ▶</div>
                                          {verifyResults.map(verifyResult => verifyResult.tags).map((tags, index) => (
                                                tags && tags.length == 3 &&
                                                <div className={styles.tags1} key={index} onClick={()=>{updateHotpleField("tags",tags[0],0);updateHotpleField('tags',tags[1],1);updateHotpleField('tags',tags[2],2);}}>
                                                      <div className={styles.maincategorytext}>{tags[0]}/{tags[1]}/{tags[2]}</div>
                                                </div>
                                          ))}
                                    </div>
                              }

                              <div className={styles.captionframe} onClick={()=>setHotpleModalPage(23)}>
                                    <div className={styles.captiontext}>{editedHotple.caption}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              {verifyResults.some(verifyResult => verifyResult.caption) &&
                                    <div className={styles.captionframe}>
                                          <div className={styles.commentdivTaggedBy}> ▶</div>
                                          {verifyResults.map(verifyResult => verifyResult.caption).map((caption, index) => (
                                                caption &&
                                                <div className={styles.captiontext} key={index} onClick={()=>updateHotpleField("caption",caption)}>{caption}</div>
                                          ))}
                                    </div>
                              }

                              <div className={styles.periodframe} onClick={()=>setHotpleModalPage(23)}>
                                    <div className={styles.label1}>기간:</div>
                                    <div className={styles.periodtext}>{editedHotple.start_date?editedHotple.start_date:"시작일 없음"} ~ {editedHotple.end_date?editedHotple.end_date:"종료일 없음"}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              {verifyResults.some(verifyResult => (verifyResult.has_period!=null || verifyResult.start_date || verifyResult.end_date)) &&
                                    <div className={styles.periodframe}>
                                    <div className={styles.commentdivTaggedBy}> ▶</div>
                                    {verifyResults.map((verifyResult, index) => {
                                          return (verifyResult.has_period != null || verifyResult.start_date || verifyResult.end_date) ? (
                                                <div 
                                                      key={index} 
                                                      className={styles.periodtext} 
                                                      onClick={() => {
                                                      updateHotpleField("start_date", verifyResult.start_date);
                                                      updateHotpleField("end_date", verifyResult.end_date);
                                                      updateHotpleField("has_period", verifyResult.start_date!=null || verifyResult.end_date!=null);
                                                      }}
                                                >
                                                      {verifyResult.start_date ? verifyResult.start_date : "시작일 없음"} ~ {verifyResult.end_date ? verifyResult.end_date : "종료일 없음"}
                                                </div>
                                          ) : null;
                                    })}
                                  </div>
                              }

                              <div className={styles.placeframe} onClick={()=>setHotpleModalPage(24)}>
                                    <div className={styles.label1}>장소:</div>
                                    <div className={styles.periodtext}>{editedHotple.place_name}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              {verifyResults.some(verifyResult => verifyResult.place_name) &&
                                    <div className={styles.placeframe}>
                                          <div className={styles.commentdivTaggedBy}> ▶</div>
                                          {verifyResults.map((verifyResult, index) => (
                                                verifyResult.place_name &&
                                                <div className={styles.periodtext} key={index} onClick={()=>{
                                                      updateHotpleField("place_name",verifyResult.place_name);updateHotpleField("address",verifyResult.address);updateHotpleField("region",verifyResult.region);updateHotpleField("latitude",verifyResult.latitude);updateHotpleField("longitude",verifyResult.longitude);
                                                }}>
                                                      {verifyResult.place_name}
                                                </div>
                                          ))}
                                    </div>
                              }

                              <div className={styles.placeframe} onClick={()=>setHotpleModalPage(24)}>
                                    <div className={styles.label1}>주소:</div>
                                    <div className={styles.periodtext}>{editedHotple.address}</div>
                                    <img className={styles.iconpen} alt="" src="images/pen.svg" />
                              </div>
                              {verifyResults.some(verifyResult => verifyResult.address) &&
                                    <div className={styles.placeframe}>
                                          <div className={styles.commentdivTaggedBy}> ▶</div>
                                          {verifyResults.map((verifyResult, index) => (
                                                verifyResult.address &&
                                                <div className={styles.periodtext} key={index} onClick={()=>{
                                                      updateHotpleField("place_name",verifyResult.place_name);updateHotpleField("address",verifyResult.address);updateHotpleField("region",verifyResult.region);updateHotpleField("latitude",verifyResult.latitude);updateHotpleField("longitude",verifyResult.longitude);
                                                }}>
                                                      {verifyResult.address}
                                                </div>
                                          ))}
                                    </div>
                              }
                        </div>
                  </div>
                  
                  <div className={styles.finishButton} onClick={handleNextButtonClick}>
                        <div className={styles.nametext}>정보 수정</div>
                  </div>
            </div>
      );
};

export default EditRequestHotpleModal;