import { useEffect, useState } from 'react';
import styles from './ReviewHotpleModal1.module.css';
import DetailHeader from '../DetailHeader/DetailHeader';


const ReviewHotpleModal1 = ({setHotpleModalPage, editedHotple, updateHotpleField, originalHotple, updateHotpleInfo, isEditRequest=false}) => {
      const [beforeInfo, setBeforeInfo] = useState(null);

      function getTextWidth(text, font) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = font;
            return context.measureText(text).width+2;
      }

      const handleNextButtonClick = async () => {
            if(editedHotple.name==''||editedHotple.sub_category1==''||editedHotple.sub_category2=='')
                  return;

            setHotpleModalPage(2)
      };

      const handleCancelButtonClick = () => {
            updateHotpleField("name", beforeInfo.name);
            updateHotpleField("main_category", beforeInfo.main_category);
            updateHotpleField("sub_category1", beforeInfo.sub_category1);
            updateHotpleField("sub_category2", beforeInfo.sub_category2);
            if(isEditRequest)
                  setHotpleModalPage(21);
            else
                  setHotpleModalPage(11);
      };

      const handleEditButtonClick = () => {
            if(editedHotple.name==''||editedHotple.sub_category1==''||editedHotple.sub_category2=='')
                  return;

            if(isEditRequest)
                  setHotpleModalPage(21);
            else
                  setHotpleModalPage(11);
      };

      useEffect(() => {
            const subCategoryInput1 = document.getElementById('subCategoryInput1');
            const subCategoryInput2 = document.getElementById('subCategoryInput2');

            subCategoryInput1.addEventListener('input', function () {
                  const font = window.getComputedStyle(subCategoryInput1).font;  // 현재 input의 폰트 스타일을 가져오기
                  const textWidth = getTextWidth(this.value, font);  // 입력된 텍스트의 실제 길이를 계산
                  this.style.width = textWidth + 'px';  // 텍스트 길이에 맞춰 width 조정
            });
            subCategoryInput2.addEventListener('input', function () {
                  const font = window.getComputedStyle(subCategoryInput2).font;  // 현재 input의 폰트 스타일을 가져오기
                  const textWidth = getTextWidth(this.value, font);  // 입력된 텍스트의 실제 길이를 계산
                  this.style.width = textWidth + 'px';  // 텍스트 길이에 맞춰 width 조정
            });

            const initialFont = window.getComputedStyle(subCategoryInput1).font;
            const initialWidth1 = getTextWidth(editedHotple.sub_category1, initialFont);
            const initialOriWidth1 = getTextWidth(originalHotple.sub_category1, initialFont);
            if(editedHotple.sub_category1!='')
                  subCategoryInput1.style.width = initialWidth1 + 'px';
            else
                  subCategoryInput1.style.width = initialOriWidth1 + 'px';

            const initialWidth2 = getTextWidth(editedHotple.sub_category2, initialFont);
            const initialOriWidth2 = getTextWidth(originalHotple.sub_category2, initialFont);
            if(editedHotple.sub_category2!='')
                  subCategoryInput2.style.width = initialWidth2 + 'px';
            else
                  subCategoryInput2.style.width = initialOriWidth2 + 'px';
      }, [editedHotple]);

      useEffect(() => {
            if(!originalHotple.need_verify)
                  setBeforeInfo(editedHotple);
      },[]);

      return (
            <div className={styles.reviewmodal1}>
                  <div className={styles.mainframe}>
                        <div className={styles.headerframe}>
                              <div className={styles.title}>
                                    <b className={styles.aiContainer}>
                                          {originalHotple.need_verify ?
                                          <>
                                                <p className={styles.ai}>등록된 정보가 정확한지</p>
                                                <p className={styles.ai}>확인 부탁드려요 :)</p>
                                          </>
                                          :
                                          <>
                                                <p className={styles.ai}>어떻게 수정할까요?</p>
                                          </>
                                          }
                                    </b>
                              </div>
                              <a className={styles.instagrambutton} href={originalHotple.media_url} target="blank" rel="noopener noreferrer">
                                    <div className={styles.label}>게시글 확인하고 오기 →</div>
                              </a>
                              
                              {originalHotple.media_caption &&
                                    <DetailHeader hotple={editedHotple} updateHotpleField={updateHotpleField} updateHotpleInfo={updateHotpleInfo}/>
                              }
                        </div>
                        <div className={styles.contentsframe}>
                              <div className={styles.nameframe}>
                                    <div className={styles.div}>이름 :</div>
                                    <div className={styles.nameinput}>
                                          <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                          <input
                                                type="text"
                                                id="nameInput1"
                                                className={styles.nameInput1}
                                                value={editedHotple.name}
                                                onChange={(e) => updateHotpleField("name",e.target.value)}
                                                placeholder={originalHotple.name}
                                                autoComplete="off"
                                          />
                                    </div>
                              </div>
                              <div className={styles.categoryframe}>
                                    <div className={styles.div}>분류 :</div>
                                    <div className={styles.buttonsframe}>
                                          <div className={editedHotple.main_category=="음식점"? styles.culturalbutton: styles.restarantbutton} onClick={()=>updateHotpleField("main_category","음식점")}>
                                                <img className={styles.iconrestaurant} alt="" src="images/restaurant.svg" />
                                                <div className={styles.label1}>음식점</div>
                                          </div>
                                          <div className={editedHotple.main_category=="주점"? styles.culturalbutton: styles.restarantbutton} onClick={()=>updateHotpleField("main_category","주점")}>
                                                <img className={styles.iconrestaurant} alt="" src="images/alcohol.svg" />
                                                <div className={styles.label1}>주점</div>
                                          </div>
                                          <div className={editedHotple.main_category=="카페디저트"? styles.culturalbutton: styles.restarantbutton} onClick={()=>updateHotpleField("main_category","카페디저트")}>
                                                <img className={styles.iconrestaurant} alt="" src="images/cafe.svg" />
                                                <div className={styles.label1}>카페/디저트</div>
                                          </div>
                                          <div className={editedHotple.main_category=="팝업스토어"? styles.culturalbutton: styles.restarantbutton} onClick={()=>updateHotpleField("main_category","팝업스토어")}>
                                                <img className={styles.iconrestaurant} alt="" src="images/popupStore.svg" />
                                                <div className={styles.label1}>팝업스토어</div>
                                          </div>
                                          <div className={editedHotple.main_category=="오락액티비티"? styles.culturalbutton: styles.restarantbutton} onClick={()=>updateHotpleField("main_category","오락액티비티")}>
                                                <img className={styles.iconrestaurant} alt="" src="images/activity.svg" />
                                                <div className={styles.label1}>오락/액티비티</div>
                                          </div>
                                          <div className={editedHotple.main_category=="문화예술"? styles.culturalbutton: styles.restarantbutton} onClick={()=>updateHotpleField("main_category","문화예술")}>
                                                <img className={styles.iconrestaurant} alt="" src="images/smile.svg" />
                                                <div className={styles.label1}>문화/예술</div>
                                          </div>
                                          <div className={editedHotple.main_category=="자연힐링"? styles.culturalbutton: styles.restarantbutton} onClick={()=>updateHotpleField("main_category","자연힐링")}>
                                                <img className={styles.iconrestaurant} alt="" src="images/flower.svg" />
                                                <div className={styles.label1}>자연/힐링</div>
                                          </div>
                                          <div className={editedHotple.main_category=="기타"? styles.culturalbutton: styles.restarantbutton} onClick={()=>updateHotpleField("main_category","기타")}>
                                                <div className={styles.label1}>기타</div>
                                          </div>
                                    </div>
                              </div>
                              <div className={styles.subcategoryframe}>
                                    <div className={styles.div2}>하위 분류 :</div>
                                    <div className={styles.subcateries}>
                                          <div className={styles.subcategory1}>
                                                <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                                <input
                                                      type="text"
                                                      id="subCategoryInput1"
                                                      className={styles.subCategoryInput1}
                                                      value={editedHotple.sub_category1}
                                                      onChange={(e) => updateHotpleField("sub_category1",e.target.value)}
                                                      placeholder={originalHotple.sub_category1}
                                                      autoComplete="off"
                                                />
                                          </div>
                                          <div className={styles.div3}>{`>`}</div>
                                          <div className={styles.subcategory1}>
                                                <img className={styles.iconpen} alt="" src="images/pen.svg" />
                                                <input
                                                      type="text"
                                                      id="subCategoryInput2"
                                                      className={styles.subCategoryInput1}
                                                      value={editedHotple.sub_category2}
                                                      onChange={(e) => updateHotpleField("sub_category2",e.target.value)}
                                                      placeholder={originalHotple.sub_category2}
                                                      autoComplete="off"
                                                />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  {originalHotple.need_verify?
                        <div className={
                              editedHotple.name==''||editedHotple.sub_category1==''||editedHotple.sub_category2==''
                              ? styles.nextButton
                              : styles.nextButtonActive}
                              disabled={editedHotple.name==''||editedHotple.sub_category1==''||editedHotple.sub_category2==''}
                              onClick={handleNextButtonClick}>
                              <div className={styles.text}>다음으로 (1/3)</div>
                        </div>
                  :
                        <div className={styles.editbuttonsframe}>
                              <div className={styles.cancelButton} onClick={()=>handleCancelButtonClick()}>
                                    <div className={styles.cancellabel}>돌아가기</div>
                              </div>
                              <div className={editedHotple.name==''||editedHotple.sub_category1==''||editedHotple.sub_category2==''
                              ?styles.editButtonDisabled
                              :styles.editButton}
                              disabled={editedHotple.name==''||editedHotple.sub_category1==''||editedHotple.sub_category2==''}
                              onClick={()=>handleEditButtonClick()}>
                                    <div className={styles.edittext}>수정하기</div>
                              </div>
                        </div>
                  }
            </div>);
};

export default ReviewHotpleModal1;